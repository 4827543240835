import { useAuth } from "contexts/AuthContext";
import {
  orderRoute,
  OsteoboostOrderSlug
} from "pages/order/OsteoboostOrderPage";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const useOsteoboostOrderRoute = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    // The user is unauthenticated and does not have a complete order
    if (!isAuthenticated) {
      // Allow some routes to not be redirected to login when unauthenticated
      if (
        location.pathname === orderRoute(OsteoboostOrderSlug.Signup) ||
        location.pathname === orderRoute(OsteoboostOrderSlug.AuthCallback)
      ) {
        return;
      }

      // If we're not authenticated then we should show the login page
      // When login completes, we redirect to payments
      navigate(
        { pathname: orderRoute(OsteoboostOrderSlug.Login) },
        {
          replace: true,
          state: {
            from: location.pathname,
            returnTo: orderRoute(OsteoboostOrderSlug.Payment)
          }
        }
      );
    }
    // The user is authenticated.
    else {
      const returnTo = state?.returnTo;
      // Avoid redirecting if the user is navigating back
      if (state?.from && !returnTo && location.pathname === state.from) {
        return;
      }

      // If the user is authenticated and landing directly on /order,
      // redirect them to the beginning of the order flow
      if (location.pathname === orderRoute(OsteoboostOrderSlug.Root)) {
        navigate(
          { pathname: orderRoute(OsteoboostOrderSlug.Payment) },
          { replace: true }
        );
        return;
      }

      // Redirect to payment page if the user is authenticated and has a user account while on the login or signup page
      if (
        location.pathname === orderRoute(OsteoboostOrderSlug.Login) ||
        location.pathname === orderRoute(OsteoboostOrderSlug.Signup)
      ) {
        if (returnTo && location.pathname !== returnTo) {
          navigate(returnTo, { replace: true });
        } else {
          navigate(orderRoute(OsteoboostOrderSlug.Payment), { replace: true });
        }
      }

      // For any sub-route in /order/:slug with a returnTo path, redirect there automatically
      if (returnTo && location.pathname !== returnTo) {
        navigate({ pathname: returnTo }, { replace: true });
      }
    }
  }, [location.pathname]);
};
