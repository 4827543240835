import { notify } from "@getwellen/valesco";
import { useAuth } from "contexts/AuthContext";
import { useCurrentUser } from "contexts/CurrentUserContext";
import { useFeatureFlag } from "contexts/FeatureFlagContext";
import { AccountSetupInput, useIntake } from "contexts/IntakeContext";
import { User } from "graphql/rails-api";
import {
  Enum_Subscriptionplan_Interval,
  Enum_Subscriptionplan_Plantype,
  useFitnessProfileQuizQuery
} from "graphql/strapi-cms";
import { usePostLoginAnalytics } from "pages/auth/usePostLogin";
import LoadingPage from "pages/LoadingPage";
import { useIntakeCreateUser } from "pages/overlays/intake/useIntakeCreateUser";
import React, { memo, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { IntakePageSlug } from "./IntakePage";

const IntakePageAuthCallback: React.FC = () => {
  const { getAccessTokenSilently, authUser, authError, logout } = useAuth();
  const { healthHistory, selectedPlan } = useIntake();
  const { currentUser } = useCurrentUser();
  const navigate = useNavigate();

  const { createUser, updateUser } = useIntakeCreateUser(healthHistory);
  const { postLoginAnalytics } = usePostLoginAnalytics();
  const { isFeatureEnabled } = useFeatureFlag();

  const planType = (
    selectedPlan?.planType || Enum_Subscriptionplan_Plantype.Starter
  ).toLowerCase();
  const interval = (
    selectedPlan?.interval || Enum_Subscriptionplan_Interval.Monthly
  ).toLowerCase();

  useFitnessProfileQuizQuery();

  useEffect(() => {
    if (authError) {
      navigate("/intake/choose-your-plan?authError=" + authError, {
        replace: true
      });
    } else if (authUser && !authUser?.email) {
      logout({
        returnTo: "/intake/choose-your-plan?authError=email_required",
        resetOnboarding: false
      });
    } else if (!authUser && !authError) {
      logout({
        returnTo: "/intake/choose-your-plan?authError=unknown_error",
        resetOnboarding: false
      });
    } else if (currentUser) {
      updateUser()
        .then(() => {
          postLoginAnalytics(currentUser);
          navigate(`/intake/${IntakePageSlug.FitnessProfile}/1`, {
            replace: true,
            state: {
              openOverlay:
                !currentUser?.isTrialing &&
                isFeatureEnabled("web-app_onboarding-trial")
                  ? `/subscribe/${planType}/start-trial/${interval}`
                  : undefined
            }
          });
        })
        .catch(() => {
          notify.error("Authentication error");
        });
    } else if (authUser && !currentUser) {
      createUser({ accessToken: getAccessTokenSilently() }, {
        firstName: authUser.given_name as string,
        lastName: authUser.family_name ?? "",
        provider: authUser.sub.split("|")[0] as string,
        uid: authUser.sub.split("|")[1] as string,
        email: authUser.email
      } as AccountSetupInput).then((user: User) => {
        navigate(`/intake/${IntakePageSlug.FitnessProfile}/1`, {
          replace: true,
          state: {
            openOverlay:
              !user?.isTrialing && isFeatureEnabled("web-app_onboarding-trial")
                ? `/subscribe/${planType}/start-trial/${interval}`
                : undefined
          }
        });
      });
    }
  }, []);

  return <LoadingPage />;
};

export default memo(IntakePageAuthCallback);
