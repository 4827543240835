import { useDashboardPageQuery } from "graphql/strapi-cms";

const useDashboard = () => {
  const { data: dashboardPageData, loading: isDashboardPageLoading } =
    useDashboardPageQuery();

  const dashboardPage = dashboardPageData?.dashboardPage?.data?.attributes;

  return {
    dashboardPage,
    isLoading: isDashboardPageLoading
  };
};

export { useDashboard };
