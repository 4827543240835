import { Button } from "@getwellen/valesco";
import {
  ArrowRightIcon,
  ExclamationTriangleIcon,
  XMarkIcon
} from "@heroicons/react/24/solid";
import React, { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { resolveBackgroundLocation } from "utils/location";

const PaymentFailureBanner: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isVisible, setIsVisibile] = useState<boolean>(true);

  const onClick = useCallback(() => {
    navigate("/my-subscription/edit-payment", {
      state: {
        backgroundLocation: resolveBackgroundLocation(location)
      }
    });
  }, []);

  const onClose = useCallback(() => {
    setIsVisibile(false);
  }, []);

  if (!isVisible) return null;

  return (
    <div className="relative bg-gray-700 px-4 py-10 text-white md:px-24 md:py-10">
      <button onClick={onClose}>
        <XMarkIcon className="absolute right-4 top-4 size-6 md:right-8 md:top-8" />
      </button>
      <ExclamationTriangleIcon className="mb-2 size-8 text-geebung-200" />
      <p className="mb-4 font-semibold">
        We&apos;re having issues processing your payment. To continue using
        Wellen, please update your payment info. If you have questions or need
        assistance, please email us at{" "}
        <a
          className="underline"
          href="mailto:support@getwellen.com"
          rel="noreferrer"
          target="__blank"
        >
          support@getwellen.com
        </a>
        .
      </p>
      <Button action="contrast" onClick={onClick} size="large" variant="subtle">
        Update payment info <ArrowRightIcon className="ml-2 h-6 w-5" />
      </Button>
    </div>
  );
};

export { PaymentFailureBanner };
