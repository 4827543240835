import { Button, Checkbox, Radio } from "@getwellen/valesco";
import { LoadingSkeleton } from "components/loading/LoadingSkeleton";
import {
  ComponentContentQuizAnswer,
  Enum_Componentcontentquizquestion_Type
} from "graphql/strapi-cms";
import React from "react";

interface QuizAnswer extends ComponentContentQuizAnswer {
  checked?: boolean;
  questionType: Enum_Componentcontentquizquestion_Type;
  onQuizAnswerChanged(e: React.ChangeEvent<HTMLInputElement>): void;
  onQuizAnswerSelected(e: React.MouseEvent<HTMLButtonElement>): void;
  isLoading: boolean;
}

const QuizAnswer: React.FC<QuizAnswer> = ({
  text,
  apiId,
  checked = false,
  onQuizAnswerChanged,
  onQuizAnswerSelected,
  questionType = Enum_Componentcontentquizquestion_Type.Single,
  isLoading
}) => (
  <>
    {isLoading && (
      <LoadingSkeleton containerClassName="flex-1 w-full" width="50%" />
    )}
    {!isLoading &&
      questionType === Enum_Componentcontentquizquestion_Type.Single && (
        <Radio
          checked={checked}
          label={text || ""}
          onChange={onQuizAnswerChanged}
          value={apiId}
        />
      )}
    {!isLoading &&
      questionType === Enum_Componentcontentquizquestion_Type.Multiple && (
        <Checkbox
          checked={checked}
          label={text || ""}
          onChange={onQuizAnswerChanged}
          value={apiId}
        />
      )}
    {!isLoading &&
      questionType === Enum_Componentcontentquizquestion_Type.Binary &&
      (text?.toLowerCase() === "yes" ? (
        <Button
          action="secondary"
          className="mr-3 w-40"
          onClick={onQuizAnswerSelected}
          size="large"
          value="yes"
          variant="bold"
        >
          Yes
        </Button>
      ) : (
        <Button
          action="secondary"
          className="w-40"
          onClick={onQuizAnswerSelected}
          size="large"
          value="no"
          variant="bold"
        >
          No
        </Button>
      ))}
  </>
);

export { QuizAnswer };
