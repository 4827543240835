import { useIntake } from "contexts";
import { useCurrentUser } from "contexts/CurrentUserContext";
import { useEffect } from "react";

const useCurrentUserProfile = () => {
  const { currentUserIsLoading, currentUser } = useCurrentUser();
  const { setFitnessProfile, setHealthHistory } = useIntake();

  useEffect(() => {
    if (currentUserIsLoading) return;

    if (Number(currentUser?.userProfile?.fitness?.items?.length) > 0) {
      const fitnessProfile = currentUser?.userProfile?.fitness?.items || [];
      setFitnessProfile(
        fitnessProfile.map((item) => ({
          key: item.key,
          value: item.value
        }))
      );
    }

    if (Number(currentUser?.userProfile?.healthHistory?.items?.length) > 0) {
      const healthHistory =
        currentUser?.userProfile?.healthHistory?.items || [];

      setHealthHistory(
        healthHistory.map((item) => ({
          key: item.key,
          value: item.value
        }))
      );
    }
  }, [currentUser, currentUserIsLoading]);
};

export { useCurrentUserProfile };
