import { useAuth } from "contexts/AuthContext";
import { useCurrentUser } from "contexts/CurrentUserContext";
import { IntakePageSlug } from "pages/intake/IntakePage";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const useOnboardingRoute = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated } = useAuth();
  const { currentUserIsOnboarded, currentUserHasSubscription } =
    useCurrentUser();
  const canRedirect = location.pathname !== "/intake/completeOnboarding";

  useEffect(() => {
    if (
      isAuthenticated &&
      currentUserHasSubscription &&
      currentUserIsOnboarded &&
      canRedirect
    ) {
      navigate({
        pathname: "/dashboard",
        search: location.search
      });
    } else if (location.pathname === "/intake") {
      navigate(
        {
          pathname: `/intake/${IntakePageSlug.HealthHistory}/1`,
          search: location.search
        },
        { replace: true }
      );
    }
  }, []);
};

export { useOnboardingRoute };
