import { ComponentType, lazy } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ComponentPromise<T = any> = Promise<{ default: ComponentType<T> }>;

const lazyRetry = (
  component: () => ComponentPromise
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): React.LazyExoticComponent<React.ComponentType<any>> => {
  return lazy(() => retry(component));
};

function retry(fn: () => ComponentPromise): ComponentPromise {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        if (!window.location.hash) {
          window.location.href = window.location.href + "#retry";
          window.location.reload();
        }
        reject(error);
      });
  });
}

export { lazyRetry };
