import { Spinner } from "@getwellen/valesco";
import React, { memo } from "react";

const LoadingPage: React.FC = () => (
  <div className="flex min-h-screen w-screen items-center justify-center bg-cararra-100">
    <Spinner className="fill-geebung-500" />
  </div>
);

export default memo(LoadingPage);
