import React from "react";
import { Outlet } from "react-router-dom";

import { usePrivateRoute } from "./usePrivateRoute";

const PrivateRoute: React.FC = () => {
  const { isReady } = usePrivateRoute();

  return isReady ? <Outlet /> : null;
};

export default PrivateRoute;
