import { Checkbox, DateInput, Select, TextInput } from "@getwellen/valesco";
import type {
  DrugAllergy,
  MedicalConditionsFormType,
  PatientFormType
} from "contexts/OsteoboostOrderContext";
import {
  DefaultDrugAllergy,
  DefaultMedicalConditions,
  DefaultPatientForm,
  PatientKey,
  useOsteoboostOrder
} from "contexts/OsteoboostOrderContext";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  errorHelpText,
  formatCommaSeparatedList,
  transformDateToISO,
  validateAge,
  validateCommaSeparatedList,
  validateDateOfBirth,
  validNamePattern
} from "utils/forms";

import { FormStepButtons, FormStepChildProps } from "./FormStep";

const DrugAllergiesLabels: { [drug: string]: string } = {
  none: "I don’t have any drug allergies",
  cephalosporins: "Cephalosporins (Cephalexin, Cefaclor, Feftin)",
  clindamycin: "Clindamycin",
  codeine: "Codeine (Vicodin, Hydrocodone)",
  demerol: "Demerol",
  iodine: "Iodine",
  macrolides: "Macrolides (Erythromycin, Azithromycin, Clarithromycin)",
  morphine: "Morphine",
  nsaidsAspirin: "NSAIDs/Aspirin (Naproxen, Ibuprofen, Ketoprofen)",
  penicillin: "Penicillin (Amoxicillin, Augmentin)",
  sulfonamides:
    "Sulfonamides (Bactrim, Sulfamethoxazole/Trimethoprim, Hydrochlorothiazide, Septra)",
  tetracycline: "Tetracycline"
};

const MedicalConditionsLabels: { [condition: string]: string } = {
  alzheimers: "Alzheimer's disease",
  cancer: "Cancer",
  cerebrovascularDisease: "Cerebrovascular disease",
  chronicLowerRespiratoryDisease: "Chronic lower respiratory disease",
  diabetes: "Diabetes",
  heartDisease: "Heart disease",
  influenza: "Influenza",
  kidneyDisease: "Kidney disease",
  pneumonia: "Pneumonia",
  septicemia: "Septicemia",
  none: "No medical condition"
};

const genderOptions = [
  { label: "Female", value: "female" },
  { label: "Male", value: "male" }
];

const pregnantOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false }
];

const defaultOtherMedications = "Comma separated list of medications";

type PatientFormProps = FormStepChildProps;

type PatienFormTypeOverride = Required<PatientFormType> & {
  noOtherMedications?: boolean;
};

export const PatientForm: React.FC<PatientFormProps> = ({
  showBack = true,
  onBack,
  onSubmit
}: PatientFormProps) => {
  const { isLoading, order, updateOrder } = useOsteoboostOrder();

  const { control, errors, watch, setValue, getValues, isValid } =
    usePatientForm(order[PatientKey]);

  // STATE
  const [otherMedicationsPlaceholder, setOtherMedicationsPlacerholder] =
    useState(defaultOtherMedications);

  // CALLBACKS
  const handleSubmit = useCallback(() => {
    // Get form values
    const values = getValues();
    const validValues = { ...values };
    delete validValues.noOtherMedications;
    validValues.pregnant = Boolean(validValues.pregnant);
    // Update the context
    updateOrder(PatientKey, validValues, onSubmit);
  }, [onSubmit]);

  const noDrugAllergies = watch("drugAllergy.none");
  const noMedicalConditions = watch("medicalConditions.none");
  const noOtherMedications = watch("noOtherMedications");

  useEffect(() => {
    if (noDrugAllergies) {
      Object.keys(DefaultDrugAllergy).forEach((key) => {
        if (key === "none") return;
        setValue(`drugAllergy.${key as keyof DrugAllergy}`, false);
      });
    }
  }, [noDrugAllergies]);

  useEffect(() => {
    if (noMedicalConditions) {
      Object.keys(DefaultMedicalConditions).forEach((key) => {
        if (key === "none") return;
        setValue(
          `medicalConditions.${key as keyof MedicalConditionsFormType}`,
          false
        );
      });
    }
  }, [noMedicalConditions]);

  useEffect(() => {
    if (noOtherMedications) {
      setValue("otherMedications", "");
      setOtherMedicationsPlacerholder("N/A");
    } else {
      setOtherMedicationsPlacerholder(defaultOtherMedications);
    }
  }, [noOtherMedications]);

  const DrugAllergiesComponents = Object.keys(DefaultDrugAllergy).map((key) => {
    const label = DrugAllergiesLabels[key];
    const canBeDisabled = key !== "none";
    const disabled = noDrugAllergies && canBeDisabled;
    return (
      <li key={key} className="mb-1 flex items-center last:mb-0">
        <Controller
          control={control}
          name={`drugAllergy.${key as keyof DrugAllergy}`}
          render={({ field: { onChange, onBlur, value } }) => (
            <Checkbox
              checked={value}
              disabled={disabled}
              label={label}
              onBlur={onBlur}
              onChange={onChange}
            />
          )}
          rules={{ required: false }}
        />
      </li>
    );
  });

  const MedicalConditionComponents = Object.keys(DefaultMedicalConditions).map(
    (key) => {
      const label = MedicalConditionsLabels[key];
      const canbeDisabled = key !== "none";
      const disabled = noMedicalConditions && canbeDisabled;
      return (
        <li key={key} className="mb-1 flex items-center last:mb-0">
          <Controller
            control={control}
            name={`medicalConditions.${key as keyof MedicalConditionsFormType}`}
            render={({ field: { onChange, onBlur, value } }) => (
              <Checkbox
                checked={value}
                disabled={disabled}
                label={label}
                onBlur={onBlur}
                onChange={onChange}
              />
            )}
            rules={{ required: false }}
          />
        </li>
      );
    }
  );

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-8 grid grid-cols-1 gap-8">
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-3">
          <Controller
            control={control}
            name="firstName"
            render={({ field: { onChange, onBlur, value } }) => (
              <TextInput
                error={!!errors.firstName}
                helpText={errorHelpText(errors.firstName)}
                label="First name*"
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
            rules={{
              required: true,
              pattern: validNamePattern,
              maxLength: {
                value: 100,
                message: "Must be less than 100 characters"
              }
            }}
          />
          <Controller
            control={control}
            name="lastName"
            render={({ field: { onChange, onBlur, value } }) => (
              <TextInput
                error={!!errors.lastName}
                helpText={errorHelpText(errors.lastName)}
                label="Last name*"
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
            rules={{
              required: true,
              pattern: validNamePattern,
              maxLength: {
                value: 100,
                message: "Must be less than 100 characters"
              }
            }}
          />
        </div>
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-3">
          <Controller
            control={control}
            name="gender"
            render={({ field: { onChange, onBlur, value } }) => (
              <Select
                error={!!errors.gender}
                helpText={errorHelpText(errors.gender)}
                label="Birth sex*"
                onBlur={onBlur}
                onChange={onChange}
                options={genderOptions.map((opt) => opt)}
                value={value}
              />
            )}
            rules={{ required: true }}
          />
          <Controller
            control={control}
            name="pregnant"
            render={({ field: { onChange, onBlur, value } }) => (
              <Select
                className="md:mr-6 mb-2 mb:mb-0"
                error={!!errors.pregnant}
                helpText={errorHelpText(errors.pregnant)}
                label="Are you pregnant?*"
                onBlur={onBlur}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  onChange(e.target.value);
                }}
                options={pregnantOptions}
                value={value}
              />
            )}
            rules={{
              validate: (v) => {
                const value = Boolean(v);
                // Consider the form valid if the default value (false) is unchanged
                if (value === null || value === undefined) {
                  return "This field is required.";
                }
                return true;
              }
            }}
          />
        </div>

        <div className="w-full">
          <Controller
            control={control}
            name="birthday"
            render={({ field: { onChange, onBlur, value } }) => (
              <DateInput
                error={!!errors.birthday}
                helpText={errorHelpText(errors.birthday)}
                label="Date of birth*"
                onBlur={onBlur}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  transformDateToISO(e.target.value, onChange);
                }}
                value={
                  // Convert ISO8601 string back to dd/MM/yyyy format for the input field
                  value
                    ? new Date(value).toLocaleDateString("en-US", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric"
                      }) // Format as MM/dd/yyyy
                    : ""
                }
              />
            )}
            rules={{
              required: true,
              validate: {
                validateDateOfBirth,
                validateAge: validateAge(18)
              }
            }}
          />
        </div>

        <div className="w-full">
          <span className="block pb-4 text-sm font-semibold text-cello-500">
            Drug allergies*
          </span>
          <ul className="grid grid-cols-1 gap-2">{DrugAllergiesComponents}</ul>
        </div>

        <ul className="w-full grid grid-cols-1 gap-1 md:gap-4">
          <li className="flex items-center">
            <Controller
              control={control}
              name="otherMedications"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  className="mb-4 md:mb-0 md:mr-4"
                  disabled={noOtherMedications}
                  error={!!errors.otherMedications}
                  helpText={errorHelpText(errors.otherMedications)}
                  label="Medications (separate medications with a comma)*"
                  onBlur={() => {
                    // Call the original onBlur handler
                    onBlur();

                    // Apply formatting when the user finishes editing the input
                    onChange(formatCommaSeparatedList(value));
                  }}
                  onChange={onChange}
                  placeholder={otherMedicationsPlaceholder}
                  value={value}
                />
              )}
              rules={{
                required: false,
                validate: validateCommaSeparatedList
              }}
            />
          </li>
          <li className="flex items-center">
            <Controller
              control={control}
              name="noOtherMedications"
              render={({ field: { onChange, onBlur, value } }) => (
                <Checkbox
                  checked={value}
                  label="I don’t take medications"
                  onBlur={onBlur}
                  onChange={onChange}
                />
              )}
            />
          </li>
        </ul>

        <div className="w-full">
          <span className="block pb-4 text-sm font-semibold text-cello-500">
            Medical conditions*
          </span>
          <ul className="grid grid-cols-1 gap-2">
            {MedicalConditionComponents}
          </ul>
        </div>

        <div className="w-full">
          <span className="block pb-4 text-sm font-semibold text-cello-500">
            Consent to electronic communications*
          </span>
          <Controller
            control={control}
            name="agreedPharmacyAuth"
            render={({ field: { onChange, onBlur, value } }) => (
              <Checkbox
                checked={value}
                label="I consent to communicate using e-mail, text message, and other electronic messages with Osteoboost."
                onBlur={onBlur}
                onChange={onChange}
              />
            )}
            rules={{ required: true }}
          />
        </div>
      </div>

      <FormStepButtons
        isValid={isValid}
        loading={isLoading}
        onBack={onBack}
        onNext={handleSubmit}
        showBack={showBack}
      />
    </form>
  );
};

// usePatientForm handles form input and GraphQL mutations for the PatientForm
const usePatientForm = (formValues = DefaultPatientForm) => {
  const {
    control,
    watch,
    formState: { errors, isValid },
    setValue,
    getValues
  } = useForm<PatienFormTypeOverride>({
    mode: "onChange",
    defaultValues: formValues
  });

  return {
    control,
    errors,
    setValue,
    getValues,
    isValid,
    watch
  };
};
