import { Button, WorkoutIcon } from "@getwellen/valesco";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import physicalTherapists from "assets/images/physical-therapists.png";
import physicalTherapists2x from "assets/images/physical-therapists@2x.png";

type PhysicalTherapyOrWellenProps = {
  title?: string;
  subtitle?: string;
  physicalTherapyCardRibbon?: string;
  physicalTherapyCardTitle?: string;
  physicalTherapyCardButtonText?: string;
  wellenCardTitle?: string;
  wellenCardButtonText?: string;
  separator?: string;
  backButtonText?: string;
  onBackClick: () => void;
  onRecommendedClick: () => void;
  onStarterClick: () => void;
};

export function PhysicalTherapyOrWellen({
  title = "Find a physical therapist",
  subtitle = "Based on your history of falls and fractures, we recommend you find a physical therapist near you for a supervised program before starting with Wellen. Let us help you get started.",
  physicalTherapyCardRibbon = "Recommended",
  physicalTherapyCardTitle = "Find a physical therapist near you for a supervised exercise program",
  physicalTherapyCardButtonText = "Next",
  wellenCardTitle = "Begin an unsupervised exercise program with Wellen",
  wellenCardButtonText = "Next",
  separator = "OR",
  backButtonText = "Back",
  onBackClick,
  onRecommendedClick,
  onStarterClick
}: PhysicalTherapyOrWellenProps) {
  return (
    <div className="mx-auto flex w-full max-w-[52rem] flex-col items-center px-4 text-center text-cello-500">
      <h1 className="mb-3 font-display text-3xl md:text-4xl">{title}</h1>
      <p className="mb-4 text-base md:mb-10 md:text-lg">{subtitle}</p>
      <div className="relative flex w-full flex-col items-center overflow-hidden rounded-lg bg-white p-4 shadow-sm md:max-w-[25rem] md:p-6">
        <h4 className="ribbon absolute right-0 top-0 m-0 bg-cello-500 p-0 py-1.5 text-sm font-semibold text-white">
          {physicalTherapyCardRibbon}
        </h4>
        <span className="mb-4">
          <picture className="h-14 w-[13.5rem]">
            <source
              srcSet={`${physicalTherapists}, ${physicalTherapists2x} 2x`}
              type="image/png"
            />
            <img alt="Physical therapists" src={physicalTherapists} />
          </picture>
        </span>
        <p className="mb-4 text-center text-base font-semibold md:mb-6 md:text-xl">
          {physicalTherapyCardTitle}
        </p>
        <Button
          action="secondary"
          className="w-full"
          onClick={onRecommendedClick}
        >
          {physicalTherapyCardButtonText}
        </Button>
      </div>
      <p className="my-4 text-center text-base uppercase md:my-6 md:text-lg">
        {separator}
      </p>
      <div className="mb-8 flex w-full flex-col items-center rounded-lg bg-white p-4 shadow-sm md:mb-10 md:max-w-[25rem] md:p-6">
        <span className="mb-4">
          <WorkoutIcon className="size-16 stroke-1 text-geebung-500 md:size-20" />
        </span>
        <p className="mb-4 text-center font-semibold md:mb-6 md:text-xl">
          {wellenCardTitle}
        </p>
        <Button
          action="secondary"
          className="w-full"
          onClick={onStarterClick}
          variant="subtle"
        >
          {wellenCardButtonText}
        </Button>
      </div>
      <Button
        action="secondary"
        className="self-start"
        onClick={onBackClick}
        variant="subtle"
      >
        <ArrowLeftIcon className="mr-2 size-5" />
        {backButtonText}
      </Button>
    </div>
  );
}
