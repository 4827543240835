import { notify } from "@getwellen/valesco";
import { useIntake } from "contexts";
import { useAnalytics } from "contexts/AnalyticsContext";
import { IntakePageData } from "contexts/IntakeContext";
import {
  useCompleteUserOnboardingMutation,
  useStartOnboardingMutation,
  useUpdateUserProfileMutation
} from "graphql/rails-api";
import {
  Enum_Subscriptionplan_Interval,
  Enum_Subscriptionplan_Plantype,
  IntakePage,
  SubscriptionPlan
} from "graphql/strapi-cms";
import { useCurrentUserProfile } from "hooks/useCurrentUserProfile";
import { useCustomerIoLeadEmail } from "hooks/useCustomerIoLeadEmail";
import { IntakePageSlug } from "pages/intake/IntakePage";
import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LocationState, resolveBackgroundLocation } from "utils/location";

import useMembershipReferral from "./useMembershipReferral";

// NOTE: @jry - IntakePage is a data type from the StrapiCMS graphql API. It is
// fetched by IntakeContext (useIntake.tsx)

const useIntakePage = (intakePage: IntakePage) => {
  const location = useLocation();
  const navigate = useNavigate();
  const analytics = useAnalytics();
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [startOnboarding] = useStartOnboardingMutation();
  const [completeOnboarding] = useCompleteUserOnboardingMutation();
  const { healthHistory, fitnessProfile, accountSetup } = useIntake();
  const [updateProfile] = useUpdateUserProfileMutation();
  const email = useCustomerIoLeadEmail();

  const { isMemberhipReferral, membershipReferral } = useMembershipReferral();

  const onFirstStepCompleted = useCallback(async () => {
    analytics.track("Onboarding Started", {
      source: "web-app"
    });

    const user = await analytics.user();

    // server-side track to start onboarding
    startOnboarding({
      context: { clientName: "rails-api" },
      variables: {
        anonymousId: user.anonymousId() as unknown as string,
        healthHistory,
        email: email || ""
      }
    });
  }, [analytics, email, startOnboarding, healthHistory]);

  const onHealthHistoryCompleted = useCallback(() => {
    navigate(`/intake/${IntakePageSlug.Recommend}`, {
      replace: true
    });
  }, [intakePage, membershipReferral]);

  const onPlanSelected = useCallback(
    (plan: SubscriptionPlan) => {
      const state = {
        backgroundLocation: resolveBackgroundLocation(location),
        healthDisclaimer: intakePage.healthDisclaimer,
        accountSetup: intakePage.accountSetup,
        selectedPlan: plan,
        intake: {
          fitnessProfile,
          accountSetup,
          healthHistory,
          selectedPlan: plan
        } as IntakePageData
      } as LocationState;

      navigate(`/subscribe/health-disclaimer`, {
        state
      });
    },
    [location, intakePage]
  );

  const onFitnessProfileCompleted = useCallback(() => {
    setIsPageLoading(true);

    updateProfile({
      context: { clientName: "rails-api" },
      variables: {
        attributes: {
          fitness: fitnessProfile
        }
      }
    })
      .then(() => {
        completeOnboarding({
          context: { clientName: "rails-api" }
        })
          .then(() => {
            setIsPageLoading(false);

            navigate(`/intake/${IntakePageSlug.CompleteOnboarding}`, {
              replace: true
            });
          })
          .catch(() => {
            setIsPageLoading(false);

            notify.error("Internal server error");
          });
      })
      .catch(() => {
        setIsPageLoading(false);

        notify.error("Internal server error");
      });
  }, [fitnessProfile]);

  useCurrentUserProfile();

  return {
    isPageLoading,
    setIsPageLoading,
    onFirstStepCompleted,
    onHealthHistoryCompleted,
    onPlanSelected,
    onFitnessProfileCompleted
  };
};

export { useIntakePage };
