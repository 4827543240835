import { ArrowPathIcon } from "@heroicons/react/24/outline";
import React from "react";

const ButtonLoading: React.FC<{
  children: React.ReactNode;
  isLoading: boolean;
}> = ({ children, isLoading }) => (
  <>
    {isLoading ? <ArrowPathIcon className="size-5 animate-spin" /> : children}
  </>
);

export { ButtonLoading };
