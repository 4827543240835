import { useCustomerIo } from "contexts/CustomerIoContext";
import { useGetLeadByUuidQuery } from "graphql/rails-api";
import { useSearchParams } from "react-router-dom";

const useCustomerIoLeadEmail = (): string | undefined => {
  const [searchParams] = useSearchParams();
  const uuid = searchParams.get("_uuid");

  // resolve directly from customerio, this this is made available
  // by th4 CustomerIoContext which looks at the _cio_id URL param
  // and then fetches it from customerio (via our api)
  const { email: customerIoEmail } = useCustomerIo();

  // resolve from a uuid parameter which is generated by us
  // and can be resolved to a lead submission made else where
  // (for example, our marketing site)
  const { data: leadData } = useGetLeadByUuidQuery({
    skip: !uuid,
    context: { clientName: "rails-api" },
    variables: {
      uuid: uuid as string
    }
  });

  return customerIoEmail || leadData?.getLeadByUuid?.email;
};

export { useCustomerIoLeadEmail };
