import { Button, LoadingSkeleton } from "@getwellen/valesco";
import weightsImg from "assets/images/woman-lifting-weights.png";
import { useCurrentUser } from "contexts/CurrentUserContext";
import { useSeenWelcomeMessageMutation } from "graphql/rails-api";
import { useDashboard } from "hooks/useDashboard";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const WelcomeModal: React.FC = ({}) => {
  const navigate = useNavigate();
  const { refetchCurrentUser, currentUserHasSeenWelcomeMessage } =
    useCurrentUser();
  const { dashboardPage, isLoading } = useDashboard();

  const [seenWelcomeMessage, { loading: welcomeMessageLoading }] =
    useSeenWelcomeMessageMutation();

  useEffect(() => {
    let ignore = currentUserHasSeenWelcomeMessage;

    if (!ignore) {
      seenWelcomeMessage({
        context: { clientName: "rails-api" }
      }).then(() => {
        refetchCurrentUser();
      });
    }

    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div className="w-100 overflow-scroll lg:rounded-lg">
      <img alt="Woman lifting weights" className="w-screen" src={weightsImg} />
      <div className="h-screen bg-white p-4 md:h-auto lg:h-auto">
        <h1 className="p-4 text-left font-display text-3xl">
          {isLoading ? (
            <LoadingSkeleton width="50%" />
          ) : (
            dashboardPage?.welcomeTitle || "Welcome to Wellen"
          )}
        </h1>
        <p className="w-100 p-4">
          {isLoading ? (
            <LoadingSkeleton count={7} />
          ) : (
            dashboardPage?.welcomeText
          )}
        </p>

        <div className={isLoading ? "block p-4" : "flex flex-col items-center"}>
          {isLoading ? (
            <LoadingSkeleton className="h-12" />
          ) : (
            <Button
              action="secondary"
              className="my-4 w-11/12"
              disabled={welcomeMessageLoading}
              onClick={() => navigate("/dashboard")}
              variant="bold"
            >
              Next
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
export { WelcomeModal };
