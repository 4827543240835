import { Button, Logo, LogoVariant } from "@getwellen/valesco";
import { ChevronDoubleLeftIcon } from "@heroicons/react/24/solid";
import React from "react";

interface NavLogo {
  onToggle?(): void;
}
const NavLogo: React.FC<NavLogo> = ({ onToggle = undefined }) => (
  <span className="mb-10 hidden items-center justify-between md:flex">
    <Logo variant={LogoVariant.Full} />
    {!!onToggle && (
      <Button
        action="secondary"
        className="items-center justify-center px-4"
        onClick={onToggle}
        variant="minimal"
      >
        <ChevronDoubleLeftIcon className="size-5 text-lynch-500" />
      </Button>
    )}
  </span>
);

export { NavLogo };
