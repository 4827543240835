import { Select, TextInput } from "@getwellen/valesco";
import {
  DefaultShippingAddressForm,
  ShippingAddressFormType,
  ShippingAddressKey,
  useOsteoboostOrder
} from "contexts/OsteoboostOrderContext";
import { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  errorHelpText,
  formatPhoneNumber,
  stateOptions,
  unformatPhoneNumber,
  validatePhoneNumber,
  validateZipcode,
  validNamePattern
} from "utils/forms";

import { FormStepButtons, FormStepChildProps } from "./FormStep";

const countryOptions = [{ label: "United States", value: "US" }];

type ShippingAddressFormProps = FormStepChildProps;

/**
 * ShippingAddress captures the user's shipping information.
 */
export const ShippingAddressForm = ({
  showBack = true,
  onBack,
  onSubmit
}: ShippingAddressFormProps) => {
  const { isLoading, order, updateOrder } = useOsteoboostOrder();
  const { control, getValues, isValid, errors } = useShippingAddressForm(
    order[ShippingAddressKey]
  );

  // CALLBACKS
  const handleSubmit = useCallback(() => {
    const values = getValues();

    // Clean the phone number by removing formatting characters
    const cleanedPhone = unformatPhoneNumber(values.phone);
    const cleanedValues = { ...values, phone: cleanedPhone };

    updateOrder(ShippingAddressKey, cleanedValues, onSubmit);
  }, [getValues, updateOrder, onSubmit]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-8 grid grid-cols-1 gap-8">
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-3">
          <Controller
            control={control}
            name="firstName"
            render={({ field: { onChange, onBlur, value } }) => (
              <TextInput
                error={!!errors.firstName}
                helpText={errorHelpText(errors.firstName)}
                label="First name*"
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
            rules={{
              required: true,
              pattern: validNamePattern,
              maxLength: {
                value: 100,
                message: "Must be less than 100 characters"
              }
            }}
          />
          <Controller
            control={control}
            name="lastName"
            render={({ field: { onChange, onBlur, value } }) => (
              <TextInput
                error={!!errors.lastName}
                helpText={errorHelpText(errors.lastName)}
                label="Last name*"
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
            rules={{
              required: true,
              pattern: validNamePattern,
              maxLength: {
                value: 100,
                message: "Must be less than 100 characters"
              }
            }}
          />
        </div>
        <Controller
          control={control}
          name="company"
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              label="Company"
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            />
          )}
        />
        <Controller
          control={control}
          name="address1"
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              error={!!errors.address1}
              helpText={errorHelpText(errors.address1)}
              label="Address / PO Box*"
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            />
          )}
          rules={{ required: true }}
        />
        <Controller
          control={control}
          name="address2"
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              label="Address / Unit"
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            />
          )}
        />
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-3">
          <Controller
            control={control}
            name="city"
            render={({ field: { onChange, onBlur, value } }) => (
              <TextInput
                error={!!errors.city}
                helpText={errorHelpText(errors.city)}
                label="City*"
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
            rules={{ required: true }}
          />
          <Controller
            control={control}
            name="state"
            render={({ field: { onChange, onBlur, value } }) => (
              <Select
                error={!!errors.state}
                helpText={errorHelpText(errors.state)}
                label="State*"
                onBlur={onBlur}
                onChange={onChange}
                options={stateOptions}
                value={value}
              />
            )}
            rules={{ required: true }}
          />
        </div>
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-3">
          <Controller
            control={control}
            name="zipcode"
            render={({ field: { onChange, onBlur, value } }) => (
              <TextInput
                error={!!errors.zipcode}
                helpText={errorHelpText(errors.zipcode)}
                label="Zip code*"
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
            rules={{
              required: true,
              validate: validateZipcode
            }}
          />
          <Controller
            control={control}
            name="country"
            render={({ field: { onChange, onBlur, value } }) => (
              <Select
                error={!!errors.country}
                helpText={errorHelpText(errors.country)}
                label="Country*"
                onBlur={onBlur}
                onChange={onChange}
                options={countryOptions}
                value={value}
              />
            )}
            rules={{ required: true }}
          />
        </div>
        <Controller
          control={control}
          name="phone"
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              error={!!errors.phone}
              helpText={errorHelpText(errors.phone)}
              label="Phone*"
              onBlur={onBlur}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const input = e.target.value;
                const formatted = formatPhoneNumber(input);
                onChange(formatted);
              }}
              value={value}
            />
          )}
          rules={{
            required: true,
            validate: validatePhoneNumber
          }}
        />
      </div>

      <FormStepButtons
        isValid={isValid}
        loading={isLoading}
        onBack={onBack}
        onNext={handleSubmit}
        showBack={showBack}
      />
    </form>
  );
};

// useShippingAddressForm handles form input and validation for the ShippingAddress component
const useShippingAddressForm = (formValues = DefaultShippingAddressForm) => {
  const {
    control,
    getValues,
    formState: { errors, isValid }
  } = useForm<ShippingAddressFormType>({
    mode: "onBlur",
    defaultValues: formValues
  });

  return {
    control,
    errors,
    isValid,
    getValues
  };
};
