import React, { createContext, useContext } from "react";

const AUTH0_DOMAIN = import.meta.env.VITE_AUTH0_DOMAIN;
const AUTH0_CLIENT_ID = import.meta.env.VITE_AUTH0_CLIENT_ID;
const AUTH0_AUDIENCE = import.meta.env.VITE_AUTH0_AUDIENCE;
const AUTH0_DB_CONNECTION = import.meta.env.VITE_AUTH0_DB_CONNECTION;
const AUTH0_RESPONSE_TYPE =
  import.meta.env.VITE_AUTH0_RESPONSE_TYPE || "token id_token";
const AUTH0_RESPONSE_MODE =
  import.meta.env.VITE_AUTH0_RESPONSE_MODE || "fragment";
const FEED_FM_TOKEN = import.meta.env.VITE_FEED_FM_TOKEN || "";
const FEED_FM_SECRET = import.meta.env.VITE_FEED_FM_SECRET || "";
const CMS_API_URL = import.meta.env.VITE_CMS_API_URL || "http://localhost:1337";
const PLATFORM_API_URL =
  import.meta.env.VITE_PLATFORM_API_URL || "http://localhost:3008";
const REDIRECT_URL =
  window.location.origin + import.meta.env.VITE_LOGOUT_REDIRECT_PATH || "";
const STRIPE_API_KEY = import.meta.env.VITE_STRIPE_API_KEY;
const STRIPE_CLIENT_SECRET = import.meta.env.VITE_STRIPE_CLIENT_SECRET;
const SEGMENT_KEY = import.meta.env.VITE_SEGMENT_KEY || "";
const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
const SHOW_STREAMING_ERROR =
  import.meta.env.VITE_SHOW_STREAMING_ERROR == "true";
const ENV = import.meta.env.NODE_ENV || "development";

interface EnvInterface {
  AUTH0_DOMAIN: string | undefined;
  AUTH0_CLIENT_ID: string | undefined;
  AUTH0_AUDIENCE: string | undefined;
  AUTH0_DB_CONNECTION: string | undefined;
  AUTH0_RESPONSE_TYPE: string;
  AUTH0_RESPONSE_MODE: string;
  CMS_API_URL: string;
  FEED_FM_TOKEN: string;
  FEED_FM_SECRET: string;
  PLATFORM_API_URL: string;
  REDIRECT_URL: string;
  SEGMENT_KEY: string;
  SENTRY_DSN: string | undefined;
  STRIPE_API_KEY: string | undefined;
  STRIPE_CLIENT_SECRET: string | undefined;
  SHOW_STREAMING_ERROR: boolean;
  ENV: string;
}

const dotenv: EnvInterface = {
  AUTH0_DOMAIN,
  AUTH0_CLIENT_ID,
  AUTH0_AUDIENCE,
  AUTH0_DB_CONNECTION,
  AUTH0_RESPONSE_MODE,
  AUTH0_RESPONSE_TYPE,
  CMS_API_URL,
  FEED_FM_SECRET,
  FEED_FM_TOKEN,
  PLATFORM_API_URL,
  REDIRECT_URL,
  SENTRY_DSN,
  SEGMENT_KEY,
  STRIPE_API_KEY,
  STRIPE_CLIENT_SECRET,
  SHOW_STREAMING_ERROR,
  ENV
};

const EnvContext = createContext<EnvInterface>(dotenv);

const useEnv = () => {
  const context = useContext(EnvContext);

  if (!context) {
    throw new Error(`useEnv must be used within a EnvProvider`);
  }

  return context;
};

const EnvProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return <EnvContext.Provider value={dotenv}>{children}</EnvContext.Provider>;
};

export { EnvProvider, useEnv };
