import {
  useGetOrderLazyQuery,
  useUpdateOrderMutation
} from "graphql/rails-api";

export const useOrder = () => {
  // GRAPHQL
  const [getOrder] = useGetOrderLazyQuery({
    context: { clientName: "rails-api" },
    fetchPolicy: "network-only"
  });
  const [updateOrder] = useUpdateOrderMutation({
    context: { clientName: "rails-api" },
    fetchPolicy: "network-only"
  });

  return {
    getOrder,
    updateOrder
  };
};
