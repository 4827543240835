import { Auth0UserProfile } from "auth0-js";
import { LogoutOptions, useAuth0 } from "hooks/useAuth0";
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from "react";

interface AuthInterface {
  getAccessTokenSilently(): string | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  login(email: string, password: string): Promise<unknown>;
  logout(options?: LogoutOptions): void;
  loginSSO(connection: string, redirectUri?: string): void;
  signUp(
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ): Promise<unknown>;
  authUser: Auth0UserProfile;
  authError?: string;
  loadingComponent?: React.ReactNode;
}

export interface SessionInterface {
  expiresAt?: number;
  refreshToken?: string;
  domain?: string;
  version?: string;
}

const AuthContext = createContext<AuthInterface>({
  getAccessTokenSilently: () => "",
  isAuthenticated: false,
  isLoading: true,
  login: async () => null,
  loginSSO: () => null,
  logout: () => null,
  signUp: async () => null,
  authUser: {} as Auth0UserProfile,
  authError: undefined,
  loadingComponent: undefined
});

const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error(`useAuth must be used within a EnvProvider`);
  }

  return context;
};

const AuthProvider: React.FC<{
  children: React.ReactNode;
  loadingComponent?: React.ReactNode;
}> = ({ children, loadingComponent }) => {
  const [session, setSession] = useState<SessionInterface>(
    () => JSON.parse(localStorage.getItem("auth") || "{}") as SessionInterface
  );
  const canRefresh = useMemo(
    () =>
      session.expiresAt &&
      session.refreshToken &&
      new Date().getTime() < session.expiresAt,
    []
  );

  const onSessionChange = useCallback((newSession: SessionInterface) => {
    window.localStorage.setItem("auth", JSON.stringify(newSession));
    setSession(newSession);
  }, []);

  const onSessionInvalidate = useCallback((options?: LogoutOptions) => {
    const { apolloClient, resetOnboarding = true } = options || {};

    apolloClient?.clearStore();

    localStorage.removeItem("auth");

    if (resetOnboarding) localStorage.removeItem("intake");

    sessionStorage.clear();

    setSession({} as SessionInterface);
  }, []);

  const {
    accessToken,
    isLoading,
    authUser,
    signUp,
    login,
    loginSSO,
    logout: auth0Logout,
    authError
  } = useAuth0(
    onSessionChange,
    onSessionInvalidate,
    session?.domain,
    session?.version,
    // if we have a refresh token, pass it so we can use it
    canRefresh ? session.refreshToken : undefined
  );

  const logout = (options?: LogoutOptions) => {
    auth0Logout(options);
  };

  return (
    <AuthContext.Provider
      value={{
        getAccessTokenSilently: () => accessToken as string,
        isAuthenticated: !!accessToken,
        isLoading,
        login,
        loginSSO,
        logout,
        signUp,
        authUser,
        authError,
        loadingComponent
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider, useAuth };
