import { useGetCustomerioUserQuery } from "graphql/rails-api";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

interface CustomerIoContext {
  cioId?: string;
  email?: string;
  reset(): void;
}

const defaultState: CustomerIoContext = {
  cioId: undefined,
  email: undefined,
  reset: () => null
};

const CustomerIoContext = createContext<CustomerIoContext>(defaultState);

const getCustomerIo = (): CustomerIoContext => {
  const customerio = JSON.parse(sessionStorage.getItem("customerio") || "{}");

  return customerio;
};

const CustomerIoProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [searchParams] = useSearchParams();
  const cioId = searchParams.get("_cio_id") || undefined;

  const [customerIo, setCustomerIo] = useState<CustomerIoContext>(() =>
    getCustomerIo()
  );

  const { data } = useGetCustomerioUserQuery({
    skip: !customerIo.cioId,
    context: { clientName: "rails-api" },
    variables: {
      cioId: customerIo.cioId as string
    }
  });

  const email =
    window.sessionStorage.getItem("auth.email") ||
    data?.getCustomerioUser?.email;

  const reset = () => sessionStorage.removeItem("customerio");

  useEffect(() => {
    if (cioId) {
      const data: CustomerIoContext = { cioId, email, reset };
      sessionStorage.setItem("customerio", JSON.stringify(data));
      setCustomerIo(data);
    }
  }, []);

  useEffect(() => {
    if (email) {
      const data: CustomerIoContext = { cioId, email, reset };
      sessionStorage.setItem("customerio", JSON.stringify(data));
      setCustomerIo(data);
    }
  }, [email]);

  return (
    <CustomerIoContext.Provider value={customerIo}>
      {children}
    </CustomerIoContext.Provider>
  );
};

const useCustomerIo = () => {
  const context = useContext(CustomerIoContext);

  if (!context) {
    throw new Error("useCustomerIo must be used within a CustomerIoProvider");
  }

  return context;
};

export { CustomerIoContext, CustomerIoProvider, useCustomerIo };
