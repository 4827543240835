import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import { ComponentContentFaq } from "graphql/strapi-cms";
import React, { useState } from "react";
import Collapsible from "react-collapsible";

const PlanFaqs: React.FC<{
  className?: string;
  title: string;
  faqs?: ComponentContentFaq[];
}> = ({ className = "", title, faqs = [] }) => {
  const [openFaqs, setOpenFaqs] = useState<string[]>([]);

  return (
    <div className={className}>
      <h2 className="mb-4 text-center font-display text-3xl md:mb-6 md:text-4xl">
        {title}
      </h2>
      {faqs.map((faq, idx) => (
        <Collapsible
          key={idx}
          className="border-b border-lynch-200"
          onClosing={() =>
            setOpenFaqs(openFaqs.filter((f) => f !== (faq.title as string)))
          }
          onOpening={() => setOpenFaqs([faq.title as string, ...openFaqs])}
          trigger={
            <div className=" my-5 flex w-full flex-row items-center justify-between">
              <h4 className="font-semibold md:text-xl">{faq.title}</h4>
              <button>
                {openFaqs.includes(faq.title as string) ? (
                  <MinusIcon className="size-6" />
                ) : (
                  <PlusIcon className="size-6" />
                )}
              </button>
            </div>
          }
        >
          <p className="my-5 text-left ">{faq.text}</p>
        </Collapsible>
      ))}
    </div>
  );
};

export { PlanFaqs };
