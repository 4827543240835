import { useAuth } from "contexts/AuthContext";
import { useCurrentUser } from "contexts/CurrentUserContext";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const usePublicRoute = () => {
  const { isAuthenticated } = useAuth();
  const { currentUserIsLoading } = useCurrentUser();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const canRedirect =
    !currentUserIsLoading &&
    isAuthenticated &&
    pathname !== "/authCallback" &&
    pathname !== "/intake/completeOnboarding" &&
    !pathname.startsWith("/changePassword") &&
    !pathname.startsWith("/patient-workout/");

  useEffect(() => {
    if (canRedirect) {
      navigate("/dashboard");
    }
  }, [canRedirect]);
};

export { usePublicRoute };
