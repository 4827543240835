import { MiniPlayer } from "@getwellen/valesco";
import { LoadingSkeleton } from "components/loading/LoadingSkeleton";
import { useFeatureFlag } from "contexts/FeatureFlagContext";
import { ProfileItemAttributes } from "graphql/rails-api";
import {
  ComponentContentQuizAnswer,
  ComponentContentQuizQuestion,
  Enum_Componentcontentquizquestion_Type
} from "graphql/strapi-cms";
import React from "react";
import { twMerge } from "tailwind-merge";

import { QuizAnswer } from "./QuizAnswer";

interface QuizQuestion extends ComponentContentQuizQuestion {
  stepNum: number;
  steps: number;
  isLoading?: boolean;
  selectedAnswers: ProfileItemAttributes[];
  onQuizAnswerChanged(event: React.ChangeEvent<HTMLInputElement>): void;
  onQuizAnswerSelected(e: React.MouseEvent<HTMLButtonElement>): void;
}

const QuizQuestion: React.FC<QuizQuestion> = ({
  answers = [],
  title,
  description,
  type,
  subtitle = "",
  steps,
  stepNum,
  apiId,
  selectedAnswers = [],
  onQuizAnswerChanged,
  onQuizAnswerSelected,
  isLoading = false
}) => {
  const { isFeatureEnabled } = useFeatureFlag();
  const quizAnswers = (
    isLoading
      ? [
          { videoClip: { data: { attributes: { url: "" } } }, text: "" },
          { videoClip: { data: { attributes: { url: "" } } }, text: "" },
          { videoClip: { data: { attributes: { url: "" } } }, text: "" },
          { videoClip: { data: { attributes: { url: "" } } }, text: "" }
        ]
      : answers || []
  ) as QuizAnswer[];

  let videoAnswers: QuizAnswer[] = [];
  let nonVideoAnswers: QuizAnswer[] = quizAnswers;

  if (isFeatureEnabled("web-app_intake-video-answers")) {
    videoAnswers = quizAnswers.filter(
      (a: QuizAnswer) => a?.videoClip?.data?.attributes?.url
    );
    nonVideoAnswers = quizAnswers.filter(
      (a: QuizAnswer) => !a?.videoClip?.data?.attributes?.url
    );
  }

  const hasVideoAnswers = videoAnswers.length > 0;

  return (
    <>
      <p className="mb-3 font-accent text-lg font-normal uppercase leading-tight">
        {isLoading ? (
          <LoadingSkeleton width="10%" />
        ) : (
          `Step ${stepNum} of ${steps}`
        )}
      </p>
      <h1 className="mb-4 font-display text-4xl">
        {isLoading ? <LoadingSkeleton width="75%" /> : title}
      </h1>
      {description && (
        <p className="mb-10 text-lg">
          {isLoading ? <LoadingSkeleton width="80%" /> : description}
        </p>
      )}
      {subtitle && (
        <p className="mb-12 text-sm">
          {isLoading ? <LoadingSkeleton width="80%" /> : subtitle}
        </p>
      )}
      <div className="mb-16">
        <div
          className={twMerge(
            "mt-10 flex flex-col",
            type === Enum_Componentcontentquizquestion_Type.Binary
              ? "flex-row justify-center"
              : "",
            hasVideoAnswers
              ? "mb-8 border-b border-cararra-600 pb-2 md:grid md:grid-cols-2 md:gap-3"
              : ""
          )}
        >
          {(hasVideoAnswers ? videoAnswers : quizAnswers).map((answer, idx) => (
            <div
              key={idx}
              className={twMerge(
                "mb-6 flex flex-col items-start",
                type === Enum_Componentcontentquizquestion_Type.Binary
                  ? "mr-3"
                  : ""
              )}
            >
              {hasVideoAnswers && answer.videoClip?.data?.attributes?.url && (
                <div className="mb-4 w-full">
                  <MiniPlayer
                    poster={answer.thumbnail?.data?.attributes?.url}
                    src={answer.videoClip?.data?.attributes?.url}
                  />
                </div>
              )}
              <QuizAnswer
                {...(answer as ComponentContentQuizAnswer)}
                checked={
                  !!selectedAnswers.find(
                    (v) =>
                      v.value === (answer as QuizAnswer).apiId &&
                      v.key === apiId
                  )
                }
                isLoading={isLoading}
                onQuizAnswerChanged={onQuizAnswerChanged}
                onQuizAnswerSelected={onQuizAnswerSelected}
                questionType={
                  type || Enum_Componentcontentquizquestion_Type.Single
                }
              />
            </div>
          ))}
        </div>
        {/* // render non video answers below */}
        {hasVideoAnswers && (
          <ul
            className={twMerge(
              "flex flex-col",
              type === Enum_Componentcontentquizquestion_Type.Binary
                ? "flex-row justify-center"
                : ""
            )}
          >
            {nonVideoAnswers.map((answer, idx) => (
              <li
                key={idx}
                className={twMerge(
                  "mb-6 flex items-center",
                  type === Enum_Componentcontentquizquestion_Type.Binary
                    ? "mr-3"
                    : ""
                )}
              >
                <QuizAnswer
                  {...(answer as ComponentContentQuizAnswer)}
                  checked={
                    !!selectedAnswers.find(
                      (v) =>
                        v.value === (answer as QuizAnswer).apiId &&
                        v.key === apiId
                    )
                  }
                  isLoading={isLoading}
                  onQuizAnswerChanged={onQuizAnswerChanged}
                  onQuizAnswerSelected={onQuizAnswerSelected}
                  questionType={
                    type || Enum_Componentcontentquizquestion_Type.Single
                  }
                />
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export { QuizQuestion };
