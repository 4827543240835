import { useApolloClient } from "@apollo/client";
import { Logo, LogoVariant } from "@getwellen/valesco";
import { useAuth } from "contexts/AuthContext";
import React, { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

const Patient: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [searchParams] = useSearchParams();
  const isPreview = !!searchParams.get("providerPreview");
  const { isAuthenticated, logout } = useAuth();
  const client = useApolloClient();

  const handleLogout = useCallback(() => {
    logout({
      apolloClient: client
    });
  }, [logout]);

  return (
    <div className="min-h-screen w-full bg-cararra-200">
      <div className="flex flex-row justify-between bg-lynch-500 p-4 text-white">
        <Logo variant={LogoVariant.Full} />
        {isPreview && <p>This is what your patient sees.</p>}
        {!isPreview && !isAuthenticated && (
          <a
            className="font-semibold underline"
            href="https://www.getwellen.com?utm_source=shared_workout&utm_medium=web_app"
          >
            Join Wellen
          </a>
        )}
        {!isPreview && isAuthenticated && (
          <button className="font-semibold underline" onClick={handleLogout}>
            Logout
          </button>
        )}
      </div>
      <div className="mx-auto max-w-4xl px-4 py-8">{children}</div>
    </div>
  );
};

export { Patient };
