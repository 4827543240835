import { Button } from "@getwellen/valesco";
import React, { memo } from "react";
import { Link, useNavigate } from "react-router-dom";

const ErrorPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="flex h-full max-w-lg flex-col items-center justify-center p-16 text-center md:p-32">
      <p className="text-3xl">Error 500</p>
      <p className="mb-8 text-base">Unexpected Error</p>
      <div className="flex w-full flex-col">
        <Button
          action="contrast"
          className="mb-1"
          onClick={() => navigate(-1)}
          variant="bold"
        >
          Try Again
        </Button>
        <Link to="/">
          <Button action="contrast" className="underline" variant="minimal">
            Or, head back home
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default memo(ErrorPage);
