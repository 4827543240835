import { Interval, PlanType } from "graphql/rails-api";
import {
  Enum_Subscriptionplan_Interval,
  Enum_Subscriptionplan_Plantype
} from "graphql/strapi-cms";

const intervalUnit = (
  interval: Enum_Subscriptionplan_Interval | Interval
): string => {
  switch (interval?.toLowerCase()) {
    case Enum_Subscriptionplan_Interval.Quarterly:
      return "quarter";
    case Enum_Subscriptionplan_Interval.Annually:
      return "year";
    case Enum_Subscriptionplan_Interval.Monthly:
      return "month";
  }

  return "";
};

const toInterval = (
  interval: Enum_Subscriptionplan_Interval | Interval | string
): Interval => {
  switch (interval.toLowerCase()) {
    case Enum_Subscriptionplan_Interval.Quarterly:
      return Interval.Quarterly;
    case Enum_Subscriptionplan_Interval.Annually:
      return Interval.Annually;
    case Enum_Subscriptionplan_Interval.Monthly:
      return Interval.Monthly;
  }

  return Interval.Monthly;
};

const toPlanType = (
  planType: Enum_Subscriptionplan_Plantype | PlanType | string
): PlanType => {
  switch (planType.toLowerCase()) {
    case Enum_Subscriptionplan_Plantype.Starter:
      return PlanType.Starter;
    case Enum_Subscriptionplan_Plantype.Premium:
      return PlanType.Premium;
  }

  return PlanType.Starter;
};

const toFriendlyInterval = (
  interval: Enum_Subscriptionplan_Interval | Interval | string
): string => {
  switch (interval as string) {
    case "ANNUALLY":
      return "ANNUAL";
    default:
      return interval;
  }
};

export { intervalUnit, toFriendlyInterval, toInterval, toPlanType };
