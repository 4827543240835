import { valescoTheme } from "@getwellen/valesco";
import {
  StripeElementsOptions,
  StripePaymentElementOptions
} from "@stripe/stripe-js";

const stripeElementsOptions: StripeElementsOptions = {
  loader: "always",
  appearance: {
    // theme: "none",
    rules: {
      ".Label": {
        paddingBottom: "4px",
        fontWeight: valescoTheme.fontWeight["semibold"],
        fontSize: `${valescoTheme.fontSize["sm"][0]}`,
        color: valescoTheme.colors["cello"]["500"]
      },
      ".Input": {
        border: "1px solid",
        borderColor: valescoTheme.colors["cello"]["200"],
        padding: "16px"
      }
    },
    variables: {
      fontSmooth: "always",
      spacingUnit: "4px",
      spacingGridRow: "16px",
      borderRadius: valescoTheme.borderRadius["DEFAULT"],
      fontFamily: valescoTheme.fontFamily["body"].join(", "),
      colorDanger: valescoTheme.colors["red"]
    }
  },
  fonts: [
    {
      cssSrc:
        "https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap"
    }
  ]
};

const stripePaymentElementOptions: StripePaymentElementOptions = {
  terms: {
    card: "never"
  }
};

export { stripeElementsOptions, stripePaymentElementOptions };
