import React, { createContext, useContext, useState } from "react";

interface WorkoutProgramContext {
  workoutWasCompleted: boolean;
  assessmentWasCompleted: boolean;
  setWorkoutWasCompleted: React.Dispatch<React.SetStateAction<boolean>>;
  setAssessmentWasCompleted: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultState: WorkoutProgramContext = {
  workoutWasCompleted: false,
  assessmentWasCompleted: false,
  setWorkoutWasCompleted: () => null,
  setAssessmentWasCompleted: () => null
};

const WorkoutProgramContext =
  createContext<WorkoutProgramContext>(defaultState);

const WorkoutProgramProvider: React.FC<{ children?: React.ReactNode }> = ({
  children
}) => {
  const [workoutWasCompleted, setWorkoutWasCompleted] =
    useState<boolean>(false);
  const [assessmentWasCompleted, setAssessmentWasCompleted] =
    useState<boolean>(false);

  return (
    <WorkoutProgramContext.Provider
      value={{
        workoutWasCompleted,
        assessmentWasCompleted,
        setWorkoutWasCompleted,
        setAssessmentWasCompleted
      }}
    >
      {children}
    </WorkoutProgramContext.Provider>
  );
};

const useWorkoutProgram = () => {
  const context = useContext(WorkoutProgramContext);

  if (!context) {
    throw new Error("useContext must be used within WorkoutProgramProvider");
  }

  return context;
};

export { useWorkoutProgram, WorkoutProgramContext, WorkoutProgramProvider };
