import { PhysicalTherapyOrWellen } from "components/intake/recommend/PhysicalTherapyOrWellen";
import { useIntake } from "contexts";
import { IntakePageData } from "contexts/IntakeContext";
import {
  ProfileItemAttributes,
  useGetRecommendedSubscriptionPlanQuery
} from "graphql/rails-api";
import {
  ComponentContentPlanSelector,
  Enum_Subscriptionplan_Interval,
  Enum_Subscriptionplan_Plantype,
  SubscriptionPlan
} from "graphql/strapi-cms";
import React, { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LocationState, resolveBackgroundLocation } from "utils/location";

import { IntakePageSlug } from "../IntakePage";
import useMembershipReferral from "../useMembershipReferral";

interface IntakePlanPage extends ComponentContentPlanSelector {
  isLoading?: boolean;
}

const IntakePageRecommend: React.FC<IntakePlanPage> = ({
  premiumSubtitle = ""
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    healthHistory,
    selectedPlan,
    accountSetup,
    fitnessProfile,
    intakePage
  } = useIntake();
  const { isMemberhipReferral } = useMembershipReferral();

  const { data: recommendedPlanData, loading: recommendedPlanLoading } =
    useGetRecommendedSubscriptionPlanQuery({
      context: { clientName: "rails-api" },
      variables: {
        healthHistory: healthHistory.map(
          (item) =>
            ({
              key: item.key,
              value: item.value
            }) as ProfileItemAttributes
        )
      }
    });

  const disclaimerRedirect = () => {
    const selectedPlan =
      intakePage?.planSelector?.subscriptionPlans?.data?.find(
        (p) =>
          p.attributes?.planType === Enum_Subscriptionplan_Plantype.Starter &&
          p.attributes.interval === Enum_Subscriptionplan_Interval.Monthly
      )?.attributes as SubscriptionPlan;

    const state = {
      backgroundLocation: resolveBackgroundLocation(location),
      healthDisclaimer: intakePage.healthDisclaimer,
      accountSetup: intakePage.accountSetup,
      selectedPlan,
      intake: {
        fitnessProfile,
        accountSetup,
        healthHistory,
        selectedPlan
      } as IntakePageData
    } as LocationState;

    navigate(`/subscribe/health-disclaimer`, {
      state
    });
  };

  useEffect(() => {
    if (!recommendedPlanLoading) {
      const recommendedPlanType =
        recommendedPlanData?.getRecommendedSubscriptionPlan?.planType?.toLowerCase() ||
        Enum_Subscriptionplan_Plantype.Starter;

      const isStarter =
        recommendedPlanType === Enum_Subscriptionplan_Plantype.Starter;

      // membership referral, starter -- goto history
      if (isMemberhipReferral && isStarter) {
        disclaimerRedirect();
      }
      // not membership referral, starter -- goto select plan
      else if (
        (recommendedPlanType as Enum_Subscriptionplan_Plantype) ===
        Enum_Subscriptionplan_Plantype.Starter
      ) {
        navigate(`/intake/${IntakePageSlug.SelectPlan}`, {
          replace: true
        });
      }
    }
  }, [recommendedPlanData, recommendedPlanLoading]);

  const handleRecommendedClick = useCallback(() => {
    navigate("/subscribe/find-a-provider", {
      state: {
        backgroundLocation: resolveBackgroundLocation(location)
      } as LocationState
    });
  }, []);

  const handleStarterClick = useCallback(() => {
    if (isMemberhipReferral) {
      disclaimerRedirect();
    } else {
      navigate(`/intake/${IntakePageSlug.SelectPlan}`);
    }
  }, []);

  if (recommendedPlanLoading) return null;

  return (
    <PhysicalTherapyOrWellen
      onBackClick={() => navigate(-1)}
      onRecommendedClick={handleRecommendedClick}
      onStarterClick={handleStarterClick}
      subtitle={premiumSubtitle ? premiumSubtitle : undefined}
    />
  );
};

export { IntakePageRecommend };
