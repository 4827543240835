import { Button } from "@getwellen/valesco";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import sneakPeakImage from "assets/images/wellen-sneak-peak.jpg";
import { LoadingSkeleton } from "components/loading/LoadingSkeleton";
import { PlanFaqs } from "components/plan/PlanFaqs";
import { PlanTestimonials } from "components/plan/PlanTestimonials";
import { SubscriptionPlanCard } from "components/subscription-plan-card/SubscriptionPlanCard";
import { useIntake } from "contexts";
import { SubscriptionPlanWithPricing } from "contexts/IntakeContext";
import {
  ComponentContentFaq,
  ComponentContentPlanSelector,
  ComponentContentTestimonial,
  Enum_Subscriptionplan_Interval,
  Enum_Subscriptionplan_Plantype,
  SubscriptionPlan
} from "graphql/strapi-cms";
import React, { memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { createSubscriptionPlanSkeleton } from "utils/loading";

type IntakePlanPage = Omit<
  ComponentContentPlanSelector,
  "subscriptionPlans"
> & {
  isLoading?: boolean;
  faqs?: ComponentContentFaq[];
  testimonials?: ComponentContentTestimonial[];
  onPlanSelected: (plan: SubscriptionPlan) => void;
  subscriptionPlans?: SubscriptionPlanWithPricing[];
};

const IntakePlanPage: React.FC<IntakePlanPage> = ({
  title,
  subtitle = "",
  isLoading = false,
  subscriptionPlans = [],
  workoutPreviewTitle = undefined,
  faqs = [],
  testimonials = [],
  onPlanSelected
}) => {
  const navigate = useNavigate();
  const { setSelectedPlan } = useIntake();

  const currentPlans = isLoading
    ? ([
        createSubscriptionPlanSkeleton(),
        createSubscriptionPlanSkeleton()
      ] as SubscriptionPlanWithPricing[])
    : (subscriptionPlans || [])
        .filter((p) => p.planType === Enum_Subscriptionplan_Plantype.Starter)
        .sort((a) => (a.interval === "monthly" ? -1 : 1))
        .map((p) => p as SubscriptionPlanWithPricing);

  const handleGoBack = useCallback(() => {
    navigate(-1);
  }, []);

  return (
    <div className="flex flex-col pb-16 text-center sm:m-auto">
      <h2 className="mb-3 font-display text-4xl">
        {isLoading ? <LoadingSkeleton width="50%" /> : title}
      </h2>
      {subtitle && (
        <p className="mb-10">
          {isLoading ? <LoadingSkeleton width="75%" /> : subtitle}
        </p>
      )}
      <div className="flex flex-col justify-between md:flex-row">
        {currentPlans.map((plan, idx) => (
          <SubscriptionPlanCard
            key={idx}
            className="mb-8 w-full md:mb-10 md:first:mr-6"
            {...plan}
            ctaText={`Start your free ${plan.trialDuration}-day trial`}
            ctaType={
              plan.planType === Enum_Subscriptionplan_Plantype.Starter &&
              plan.interval !== Enum_Subscriptionplan_Interval.Monthly
                ? "primary"
                : "secondary"
            }
            isBestValue={
              plan.interval !== Enum_Subscriptionplan_Interval.Monthly
            }
            isLoading={isLoading}
            onClick={(
              _e,
              planType: Enum_Subscriptionplan_Plantype,
              interval: Enum_Subscriptionplan_Interval
            ) => {
              const selectedPlan = currentPlans?.find(
                (p) => p.planType === planType && p.interval === interval
              );

              if (!selectedPlan) return;

              setSelectedPlan(selectedPlan);
              onPlanSelected(selectedPlan);
            }}
            showPricePerMonth={
              plan.interval !== Enum_Subscriptionplan_Interval.Monthly
            }
          />
        ))}
      </div>
      <h3 className="mb-4 font-display text-3xl md:mb-6 md:text-4xl">
        {workoutPreviewTitle}
      </h3>
      <img
        alt="wellen sneak peak"
        className="mb-8 w-full rounded-xl md:mb-10"
        loading={"lazy"}
        src={sneakPeakImage}
      />
      <PlanTestimonials
        className="mb-8 w-full md:mb-10"
        testimonials={testimonials}
        title="See what our members are saying"
      />
      <PlanFaqs className="mb-8 w-full md:mb-10" faqs={faqs} title="FAQs" />
      <div className="flex flex-col justify-between md:flex-row">
        {currentPlans.map((plan, idx) => (
          <SubscriptionPlanCard
            key={idx}
            className="mb-8 w-full md:mb-10 md:first:mr-6"
            {...plan}
            ctaText={`Start your free ${plan.trialDuration}-day trial`}
            ctaType={
              plan.planType === Enum_Subscriptionplan_Plantype.Starter &&
              plan.interval !== Enum_Subscriptionplan_Interval.Monthly
                ? "primary"
                : "secondary"
            }
            hideFeatures={true}
            isBestValue={
              plan.interval !== Enum_Subscriptionplan_Interval.Monthly
            }
            isLoading={isLoading}
            onClick={(
              _e,
              planType: Enum_Subscriptionplan_Plantype,
              interval: Enum_Subscriptionplan_Interval
            ) => {
              const selectedPlan = currentPlans?.find(
                (p) => p.planType === planType && p.interval === interval
              );

              if (!selectedPlan) return;

              setSelectedPlan(selectedPlan);
              onPlanSelected(selectedPlan);
            }}
            planFeatures={[]}
            showPricePerMonth={
              plan.interval !== Enum_Subscriptionplan_Interval.Monthly
            }
          />
        ))}
      </div>
      <div className="flex w-full flex-col-reverse md:flex-row md:justify-between">
        <Button
          action="secondary"
          className="w-full md:w-32"
          onClick={handleGoBack}
          variant="subtle"
        >
          <ArrowLeftIcon className="mr-2 size-6" />
          Back
        </Button>
        <Button
          action="secondary"
          className="mb-4 w-full md:mb-0 md:w-56"
          onClick={() => {
            const monthly = currentPlans?.find(
              (p) =>
                p.planType === Enum_Subscriptionplan_Plantype.Starter &&
                p.interval === Enum_Subscriptionplan_Interval.Monthly
            );

            if (!monthly) return;

            setSelectedPlan(monthly);
            onPlanSelected(monthly);
          }}
          variant="subtle"
        >
          I&apos;ll choose a plan later
        </Button>
      </div>
    </div>
  );
};

export default memo(IntakePlanPage);
