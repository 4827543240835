import { Button } from "@getwellen/valesco";
import { CheckIcon } from "@heroicons/react/24/solid";
import { LoadingSkeleton } from "components/loading/LoadingSkeleton";
import { SubscriptionPlanWithPricing } from "contexts/IntakeContext";
import {
  ComponentContentPlanFeature,
  Enum_Subscriptionplan_Interval,
  Enum_Subscriptionplan_Plantype
} from "graphql/strapi-cms";
import React from "react";
import { twMerge } from "tailwind-merge";
import { intervalUnit } from "utils/subscription";

type SubscriptionPlanCard = SubscriptionPlanWithPricing & {
  className?: string;
  ctaText?: string;
  ctaType?: string;
  disableCta?: boolean;
  hideCta?: boolean;
  hideFeatures?: boolean;
  isBestValue?: boolean;
  isLoading?: boolean;
  overrideCtaType?: React.ComponentProps<typeof Button>["action"];
  overrideCtaVariant?: React.ComponentProps<typeof Button>["variant"];
  showPricePerMonth?: boolean;
  onClick?(
    event: React.MouseEvent<HTMLButtonElement>,
    planType: Enum_Subscriptionplan_Plantype,
    interval: Enum_Subscriptionplan_Interval
  ): void;
};

const SubscriptionPlanCard = ({
  className = "",
  ctaText = undefined,
  ctaType = "primary",
  currencySymbol = "$",
  overrideCtaType,
  overrideCtaVariant,
  disableCta = false,
  hideCta = false,
  hideFeatures = false,
  interval,
  isBestValue = false,
  isLoading = false,
  name,
  onClick,
  planFeatures = [],
  planType,
  msrp,
  msrpDisplay,
  showPricePerMonth = false,
  unitPricePerMonthDisplay,
  unitPriceDisplay,
  unitPrice,
  trialDuration = 0
}: SubscriptionPlanCard) => {
  const planFeatureList: ComponentContentPlanFeature[] =
    trialDuration > 0
      ? [
          {
            text: `Free for ${trialDuration} days`
          } as ComponentContentPlanFeature
        ]
      : [];

  if (planFeatures && planFeatures.length > 0)
    planFeatureList.push(...(planFeatures as ComponentContentPlanFeature[]));

  const displayPriceSize =
    Number(unitPriceDisplay?.length) <= 4 && Number(msrpDisplay?.length) <= 4
      ? "text-7xl"
      : "text-5xl";

  return (
    <div
      className={twMerge(
        "relative flex flex-col items-center overflow-hidden rounded-2xl bg-white p-8 shadow-md",
        className
      )}
    >
      {isBestValue && (
        <h4 className="ribbon absolute right-0 top-0 m-0 bg-cello-500 p-0 py-1.5 text-sm font-semibold text-white">
          Best Value
        </h4>
      )}

      <h6 className="w-full text-center font-accent text-lg">
        {isLoading ? (
          <LoadingSkeleton width="35%" />
        ) : (
          name?.split(" - ")?.[1] || name
        )}
      </h6>
      <div className="mb-8 text-center font-display text-cello-500">
        {isLoading ? (
          <div className="w-32">
            <LoadingSkeleton className="h-32" width="100%" />
          </div>
        ) : msrp && msrp !== unitPrice ? (
          <>
            <span className="relative inline-block">
              <span className="absolute top-[60%] block w-11/12 border-b border-cello-500"></span>
              <span className="text-4xl text-cello-200">{currencySymbol}</span>
              <span
                className={twMerge(
                  "strike mr-4 inline text-cello-200",
                  displayPriceSize
                )}
              >
                {msrpDisplay.replace(currencySymbol, "")}
              </span>
            </span>
            <span className="inline text-4xl">{currencySymbol}</span>
            <span className={twMerge("inline", displayPriceSize)}>
              {unitPriceDisplay.replace(currencySymbol, "")}
            </span>
          </>
        ) : (
          <>
            <span className="inline text-4xl">{currencySymbol}</span>
            <span className={twMerge("inline pr-1", displayPriceSize)}>
              {unitPriceDisplay.replace(currencySymbol, "")}
            </span>
          </>
        )}
        <span className="block text-center font-body font-semibold italic text-cello-500">
          {isLoading
            ? null
            : `per ${intervalUnit(interval)} ` +
              (showPricePerMonth
                ? `(${unitPricePerMonthDisplay} per month)`
                : "")}
        </span>
      </div>
      {!hideCta &&
        (isLoading ? (
          <span className="mb-8 block w-full">
            <LoadingSkeleton className="h-8" width="100%" />
          </span>
        ) : (
          <Button
            action={overrideCtaType ?? "primary"}
            className="mb-8 w-full max-w-xs disabled:cursor-not-allowed"
            disabled={disableCta}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              if (!!onClick) onClick(e, planType, interval);
            }}
            variant={
              overrideCtaVariant ?? (ctaType === "primary" ? "bold" : "subtle")
            }
          >
            {ctaText || "Get Started"}
          </Button>
        ))}
      {planFeatureList.length > 0 && !hideFeatures && (
        <ul className="w-full">
          {planFeatureList.map((feature, idx) => (
            <li
              key={idx}
              className="mb-4 flex flex-row border-b border-cararra-500 pb-4 text-left text-base last:mb-0 last:border-0 last:pb-0"
            >
              <div>
                <CheckIcon className="mr-3.5 inline-block size-4 align-middle text-geebung-500" />
              </div>
              <div className="w-60">
                {isLoading ? (
                  <LoadingSkeleton width="100%" />
                ) : (
                  <span>{feature?.text}</span>
                )}
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export { SubscriptionPlanCard };
