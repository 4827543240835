import React from "react";
import { Outlet } from "react-router-dom";

import { useDashboardRoute } from "./useDashboardRoute";

const DashboardRoute: React.FC = () => {
  const { canAccessDashboard } = useDashboardRoute();

  return canAccessDashboard ? <Outlet /> : null;
};

export default DashboardRoute;
