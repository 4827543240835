import Quiz from "components/quiz";
import { useIntake } from "contexts";
import { ProfileItemAttributes } from "graphql/rails-api";
import {
  ComponentContentQuizQuestion,
  Quiz as QuizType
} from "graphql/strapi-cms";
import { IntakePageSlug } from "pages/intake/IntakePage";
import React, { memo } from "react";
import { useNavigate } from "react-router-dom";

interface IntakePageQuiz {
  slug: string;
  quiz: QuizType;
  stepNum: number;
  isLoading?: boolean | undefined;
  onComplete?(): void;
  onNext?(stepNum: number): void;
}

const IntakePageQuiz: React.FC<IntakePageQuiz> = ({
  slug,
  quiz = {},
  stepNum = 1,
  isLoading = false,
  onComplete = () => null,
  onNext = () => null
}) => {
  const navigate = useNavigate();

  const { fitnessProfile, healthHistory, setFitnessProfile, setHealthHistory } =
    useIntake();

  const handleNext = () => {
    if (stepNum + 1 <= Number(quiz?.questions?.length)) {
      if (!!onNext) onNext(stepNum);
      navigate(`/intake/${slug}/${stepNum + 1}`);
    } else {
      onComplete();
    }
  };

  const handleQuizAnswerChanged = (
    question: ComponentContentQuizQuestion,
    answerId: string
  ) => {
    let setter:
        | React.Dispatch<React.SetStateAction<ProfileItemAttributes[]>>
        | undefined,
      existingData: ProfileItemAttributes[] = [];

    if (slug === IntakePageSlug.FitnessProfile) {
      setter = setFitnessProfile;
      existingData = fitnessProfile;
    } else if (slug === IntakePageSlug.HealthHistory) {
      setter = setHealthHistory;
      existingData = healthHistory;
    }

    if (!setter) return;

    if (question.type === "multiple") {
      const removing = !!existingData.find(
        (v) => v.key === question.apiId && v.value === answerId
      );

      if (removing) {
        setter([
          ...existingData.filter(
            (v) => !(v.key === question.apiId && v.value === answerId)
          )
        ]);
      } else {
        setter([...existingData, { key: question.apiId, value: answerId }]);
      }
    } else {
      setter([
        ...existingData.filter((v) => v.key !== question.apiId),
        { key: question.apiId, value: answerId }
      ]);
    }
  };

  const currentQuestion = quiz.questions?.[
    stepNum - 1
  ] as ComponentContentQuizQuestion;

  let selectedAnswers: ProfileItemAttributes[] = [];

  if (slug === IntakePageSlug.FitnessProfile) {
    selectedAnswers = fitnessProfile.filter(
      (item) => item.key === currentQuestion?.apiId
    );
  } else if (slug === IntakePageSlug.HealthHistory) {
    selectedAnswers = healthHistory.filter(
      (item) => item.key === currentQuestion?.apiId
    );
  }

  return (
    <div className="">
      <Quiz
        currentQuestion={currentQuestion}
        isLoading={isLoading}
        onBack={() => navigate(-1)}
        onNext={handleNext}
        onQuizAnswerChanged={handleQuizAnswerChanged}
        selectedAnswers={selectedAnswers}
        showBack={
          !(
            stepNum === 1 &&
            (slug === IntakePageSlug.FitnessProfile ||
              slug === IntakePageSlug.HealthHistory)
          )
        }
        stepNum={stepNum}
        {...(quiz || {})}
      />
    </div>
  );
};

export default memo(IntakePageQuiz);
