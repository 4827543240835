import { Radio } from "@getwellen/valesco";
import osteoboostHipMeasurementImage from "assets/images/osteoboost-hip-measurement.png";
import {
  DefaultSizingForm,
  sizeRename,
  SizingKey,
  useOsteoboostOrder
} from "contexts/OsteoboostOrderContext";
import { type SizingFormType } from "contexts/OsteoboostOrderContext";
import { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";

import { FormStepButtons, FormStepChildProps } from "./FormStep";

type SizingFormProps = FormStepChildProps & {
  description: string;
};

export const SizingForm: React.FC<SizingFormProps> = ({
  description,
  showBack,
  onBack,
  onSubmit
}: SizingFormProps) => {
  const { isLoading, products, order, updateOrder } = useOsteoboostOrder();
  const { control, getValues, isValid } = useSizingForm(order[SizingKey]);

  // CALLBACKS
  const handleSubmit = useCallback(() => {
    const values = getValues();
    updateOrder(SizingKey, values, onSubmit);
  }, [onSubmit]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex justify-between flex-row mb-8">
        <img
          alt="osteoboost sizing"
          className="mb-4 inline-block shrink-0 size-48"
          loading={"lazy"}
          src={osteoboostHipMeasurementImage}
        />
        <p className="text-sm inline-block shrink">{description}</p>
      </div>
      <div className="mb-8 grid grid-cols-1 gap-3">
        <span className="block text-sm font-semibold text-cello-500">
          Available sizes (Hip circumference)
        </span>
        <ul className="grid grid-rows-4 gap-3">
          {products.map((option) => (
            <li key={option.id}>
              <Controller
                control={control}
                name="id"
                render={({ field: { onChange, value } }) => (
                  <Radio
                    checked={value === option.id}
                    label={`${option.name} | ${sizeRename[option.size]}`}
                    onChange={onChange}
                    value={option.id}
                  />
                )}
                rules={{ required: true }}
              />
            </li>
          ))}
        </ul>
      </div>

      <FormStepButtons
        isValid={isValid}
        loading={isLoading}
        onBack={onBack}
        onNext={handleSubmit}
        showBack={showBack}
      />
    </form>
  );
};

const useSizingForm = (formValues = DefaultSizingForm) => {
  const {
    control,
    watch,
    formState: { errors, isDirty, isValid },
    getValues
  } = useForm<SizingFormType>({
    mode: "onChange",
    defaultValues: formValues
  });

  return {
    control,
    errors,
    getValues,
    isDirty,
    isValid,
    watch
  };
};
