import "./index.css";
import "react-loading-skeleton/dist/skeleton.css";

import React from "react";
import { createRoot } from "react-dom/client";

import App from "./App";
import { EnvProvider } from "./contexts";
import reportWebVitals from "./reportWebVitals";

const rootElement = document.getElementById("root");

if (!rootElement)
  throw new Error("Failed to find the root element for rendering.");

const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <EnvProvider>
      <App />
    </EnvProvider>
  </React.StrictMode>
);

//
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
