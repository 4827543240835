import React, { memo } from "react";

const MaintenancePage: React.FC = () => (
  <div className="flex h-full flex-col items-center justify-center p-16 md:p-32">
    <p className="mb-4 font-display text-3xl">Thanks for Your Patience!</p>
    <p className="mb-8 max-w-lg text-center">
      We’re currently making improvements to our site and will be back shortly.
      We sincerely apologize for any inconvenience.
    </p>
    <p className="mb-8 max-w-lg text-center">
      For any questions or assistance, feel free to contact us at{" "}
      <a
        className="font-semibold underline"
        href="mailto:support@getwellen.com"
      >
        support@getwellen.com
      </a>
    </p>
  </div>
);

export default memo(MaintenancePage);
