import React from "react";

export type OrderDetailsProps = {
  title?: string;
  children: React.ReactNode;
};

const defaultTitle = "Your order";

export const OrderDetails = ({
  title = defaultTitle,
  children
}: OrderDetailsProps) => {
  const childArray = React.Children.toArray(children);

  return (
    <div>
      <h2 className="text-base font-heading text-cello-300 font-semibold mb-4">
        {title}
      </h2>
      <div className="grid gap-8">
        {childArray.map((child, index) => (
          <React.Fragment key={index}>
            <div>{child}</div>

            {index < childArray.length - 1 && (
              <div className="h-[1px] bg-lynch-200"></div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
