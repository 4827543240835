import { useGeolocation } from "contexts/GeolocationContext";
import { useGetStripePriceByIdsQuery } from "graphql/rails-api";

const useStripePrice = (
  stripePriceIds: string[],
  currencyCode?: string,
  countryCode?: string
) => {
  const { country_code } = useGeolocation();

  const { data, loading } = useGetStripePriceByIdsQuery({
    context: {
      clientName: "rails-api"
    },
    variables: {
      stripePriceIds,
      countryCode: countryCode || country_code,
      currencyCode
    },
    skip: stripePriceIds.length === 0
  });

  return {
    data: data?.getStripePriceByIds || [],
    isLoading: loading
  };
};

export { useStripePrice };
