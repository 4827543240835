import React from "react";

export type OrderNextStepsProps = {
  title?: string;
  steps: Array<{
    title: string;
    children: React.ReactNode;
  }>;
};

const defaultTitle = "What's next?";

export const OrderNextSteps = ({
  title = defaultTitle,
  steps
}: OrderNextStepsProps) => {
  return (
    <div>
      {/* Render the title */}
      {title && (
        <h2 className="font-display text-4xl md:text-5xl mb-16">{title}</h2>
      )}

      {/* Render each step */}
      <div className="grid grid-cols-1 gap-20">
        {steps.map((step, index) => (
          <div key={index} className="grid grid-cols-[40px_1fr] gap-2 ">
            {/* Circle at the top */}
            <div className="h-full relative flex justify-center -mt-8">
              <div className="size-5 bg-transparent border-2 border-cello-300 rounded-full"></div>

              {/* Dotted vertical line */}
              <div className="absolute top-8 left-[calc(50% - 1px)] w-[1px] h-full border-l-2 border-dotted border-lynch-200"></div>
            </div>

            {/* Step content */}
            <div className="relative bg-white px-10 py-12 shadow-sm rounded-r-xl">
              <h3 className="font-display text-3xl mb-2">{step.title}</h3>
              <div className="text-cello-600">{step.children}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
