import { IconButton } from "@getwellen/valesco";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
import { LoadingSkeleton } from "components/loading/LoadingSkeleton";
import { ProfileItemAttributes } from "graphql/rails-api";
import {
  ComponentContentQuizQuestion,
  Quiz as QuizType
} from "graphql/strapi-cms";
import React from "react";
import { twMerge } from "tailwind-merge";

import { QuizQuestion } from "./QuizQuestion";

interface Quiz extends QuizType {
  stepNum?: number;
  showBack?: boolean;
  isLoading?: boolean | undefined;
  onQuizAnswerChanged(
    question: ComponentContentQuizQuestion | undefined,
    answerId: string
  ): void;
  currentQuestion: ComponentContentQuizQuestion | undefined;
  selectedAnswers: ProfileItemAttributes[];
  onBack?(): void;
  onNext?(): void;
}

const Quiz: React.FC<Quiz> = ({
  questions = [],
  showBack = false,
  currentQuestion = undefined,
  stepNum = 1,
  isLoading = false,
  onQuizAnswerChanged,
  selectedAnswers = [],
  onNext = () => null,
  onBack = () => null
}) => {
  const handleQuizAnswerChanged = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const target = e.target as HTMLInputElement;

    onQuizAnswerChanged(currentQuestion, target.value);
  };

  const handleQuizAnswerSelected = (
    e: React.MouseEvent<HTMLButtonElement>
  ): void => {
    const target = e.target as HTMLButtonElement;

    onQuizAnswerChanged(currentQuestion, target.value);
  };

  return (
    <>
      <QuizQuestion
        {...(currentQuestion as ComponentContentQuizQuestion)}
        isLoading={isLoading}
        onQuizAnswerChanged={handleQuizAnswerChanged}
        onQuizAnswerSelected={handleQuizAnswerSelected}
        selectedAnswers={selectedAnswers}
        stepNum={stepNum}
        steps={(questions || []).length}
      />
      <div
        className={twMerge(
          "mt-auto flex flex-row justify-between",
          !showBack ? "justify-end" : ""
        )}
      >
        {showBack &&
          (isLoading ? (
            <span className="block h-11 w-28 leading-none">
              <LoadingSkeleton className="h-full" />
            </span>
          ) : (
            <IconButton action="secondary" onClick={onBack} variant="subtle">
              <ArrowLeftIcon className="mr-3 size-6" />
              Back
            </IconButton>
          ))}
        {isLoading ? (
          <span className="block h-11 w-28 leading-none">
            <LoadingSkeleton className="h-full" />
          </span>
        ) : (
          <IconButton
            action="secondary"
            disabled={selectedAnswers.length === 0 || isLoading}
            onClick={onNext}
            variant="bold"
          >
            Next
            <ArrowRightIcon className="ml-3 size-6" />
          </IconButton>
        )}
      </div>
    </>
  );
};

export { Quiz };
