import "swiper/css";
import "swiper/css/pagination";

import customQuoteImage from "assets/images/custom-quote.png";
import { ComponentContentTestimonial } from "graphql/strapi-cms";
import React, { useCallback, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperCore } from "swiper/types";
import { twMerge } from "tailwind-merge";

const PlanTestimonialCard: React.FC<ComponentContentTestimonial> = ({
  name,
  text
}) => (
  <div className="rounded-lg bg-white p-6 text-left">
    <img
      // need to override a 100% width style on the image made by
      // the underlying carousel library
      alt={text as string}
      className="mb-2 !w-8"
      src={customQuoteImage}
    />
    <p className="mb-2">{text}</p>
    <h5 className="mb-1 font-accent text-lg text-lynch-400">{name}</h5>
  </div>
);

const PlanTestimonials: React.FC<{
  className?: string;
  title: string;
  testimonials?: ComponentContentTestimonial[];
}> = ({ className = "", title, testimonials = [] }) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);

  const handleSlideChange = useCallback(
    (swiper: SwiperCore) => {
      setActiveSlideIndex(swiper.realIndex);
    },
    [activeSlideIndex]
  );

  return (
    <div className={className}>
      <h2 className="mb-4 text-center font-display text-3xl md:mb-6 md:text-4xl">
        {title}
      </h2>
      {testimonials.length > 0 && (
        <>
          {/* desktop grid */}
          <div className="hidden grid-cols-3 gap-2 md:grid">
            {testimonials.slice(0, 3).map((t, idx) => (
              <PlanTestimonialCard key={idx} {...t} />
            ))}
          </div>
          {/* mobile carousel */}
          <Swiper
            centeredSlides={true}
            className="mb-4 md:!hidden"
            loop={true}
            onSlideChange={handleSlideChange}
            slidesPerView={1.5}
            spaceBetween={8}
          >
            {testimonials.map((t, idx) => (
              <SwiperSlide key={idx} className="w-1/3">
                <PlanTestimonialCard {...t} />
              </SwiperSlide>
            ))}
          </Swiper>
          <ul className="inline-flex md:hidden">
            {testimonials.map((_t, idx) => (
              <li
                key={idx}
                className={twMerge(
                  "mr-2 h-2 w-2 rounded-full border border-geebung-500 bg-transparent",
                  idx === activeSlideIndex ? "bg-geebung-500" : ""
                )}
              ></li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export { PlanTestimonials };
