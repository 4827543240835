const OsteoboostDomain = "osteoboost.com";
const WellenDomain = "getwellen.com";

export enum Domain {
  Osteoboost = "osteoboost",
  Wellen = "wellen"
}

const useHostname = () => {
  const hostname = window.location.hostname;

  if (hostname.includes(OsteoboostDomain)) {
    return Domain.Osteoboost;
  }

  if (hostname.includes(WellenDomain)) {
    return Domain.Wellen;
  }

  // localhost will return as Wellen by default
  return Domain.Wellen;
};

export default useHostname;
