import { useApolloClient } from "@apollo/client";
import { notify } from "@getwellen/valesco";
import { Auth0Result } from "auth0-js";
import { useAuth } from "contexts/AuthContext";
import { useGeolocation } from "contexts/GeolocationContext";
import { AccountSetupInput } from "contexts/IntakeContext";
import {
  GetCurrentUserDocument,
  ProfileItemAttributes,
  useCreateUserWithProfileMutation,
  User,
  useUpdateUserProfileMutation
} from "graphql/rails-api";
import {
  Enum_Subscriptionplan_Interval,
  Enum_Subscriptionplan_Plantype,
  SubscriptionPlan
} from "graphql/strapi-cms";
import { usePostLoginAnalytics } from "pages/auth/usePostLogin";
import useMembershipReferral from "pages/intake/useMembershipReferral";
import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { toInterval, toPlanType } from "utils/subscription";

const useIntakeCreateUser = (
  healthHistory: ProfileItemAttributes[],
  selectedPlan?: SubscriptionPlan,
  returnTo?: string
) => {
  const { state } = useLocation();

  const authSignUp = state?.authSignUp || undefined;
  const { membershipReferral } = useMembershipReferral();
  const geolocation = useGeolocation();

  const { getAccessTokenSilently, authUser, login, isAuthenticated } =
    useAuth();
  const [createUserWithProfile, { loading: isCreateUserLoading }] =
    useCreateUserWithProfileMutation();
  const [updateProfile, { loading: isUpdateProfileLoading }] =
    useUpdateUserProfileMutation();
  const { postLoginAnalytics, postSignupAnalytics } = usePostLoginAnalytics();
  const client = useApolloClient();

  const cachedSignUp: AccountSetupInput = (
    !!authSignUp
      ? authSignUp
      : JSON.parse(sessionStorage.getItem("auth.signup") || "{}")
  ) as AccountSetupInput;

  const loginAndCreateUser = useCallback(
    (signUpData?: AccountSetupInput) => {
      const signUp = signUpData || cachedSignUp;
      if (isAuthenticated) {
        createUser(authUser, signUp).then(() => {
          if (returnTo) {
            // When createUser is complete, navigate ourselves to the next route
            // rather than letting useOsteoboostOrderRoute from doing it
            window.location.assign(returnTo);
          }
        });
      } else {
        login(signUp.email, signUp.password)
          .then((result) => {
            return createUser(result, signUp).then(() => {
              if (returnTo) {
                // When createUser is complete, navigate ourselves to the next route
                // rather than letting useOsteoboostOrderRoute from doing it
                window.location.assign(returnTo);
              }
            });
          })
          .catch(() => {
            notify.error("Authentication error");
          });
      }
    },
    [isAuthenticated, healthHistory]
  );

  const createUser = useCallback(
    (user: unknown, accountSetup: AccountSetupInput): Promise<User> => {
      const authResult = user as Auth0Result;
      const token = authResult.accessToken || getAccessTokenSilently();

      return new Promise((resolve, reject) => {
        createUserWithProfile({
          context: {
            clientName: "rails-api",
            headers: {
              Authorization: "Bearer " + token
            }
          },
          variables: {
            userAttributes: {
              firstName: accountSetup.firstName,
              lastName: accountSetup.lastName,
              authProvider: {
                uid: accountSetup.uid,
                provider: accountSetup.provider
              },
              email: accountSetup.email,
              hasAcceptedDisclaimer: true
            },
            profileAttributes: {
              healthHistory
            },
            referralAttributes: membershipReferral,
            subscriptionAttributes: {
              interval: toInterval(
                selectedPlan?.interval || Enum_Subscriptionplan_Interval.Monthly
              ),
              planType: toPlanType(
                selectedPlan?.planType || Enum_Subscriptionplan_Plantype.Starter
              )
            },
            locationAttributes: {
              ipAddress: geolocation?.ip || "",
              country: geolocation?.country || "",
              countryCode: geolocation?.country_code || "",
              region: geolocation?.region || "",
              city: geolocation?.city || "",
              postal: geolocation?.postal || "",
              latitude: geolocation?.latitude || 0,
              longitude: geolocation?.longitude || 0,
              timezone: geolocation?.timezone || ""
            }
          }
        })
          .then((result) => {
            const user = result.data?.createUserWithProfile as User;

            client.cache.writeQuery({
              query: GetCurrentUserDocument,
              data: {
                getCurrentUser: {
                  ...user,
                  userProfile: null,
                  authProviders: null,
                  currentWorkoutPersona: null,
                  currentWorkoutType: null
                }
              }
            });

            if (user) {
              window.sessionStorage.removeItem("auth.signup");
              postSignupAnalytics();
              postLoginAnalytics(user);
              resolve(user);
            }
          })
          .catch((err) => {
            console.error(err);

            notify.error("Authentication error");
            reject(err);
          });
      });
    },
    [healthHistory, selectedPlan]
  );

  const updateUser = useCallback(
    () =>
      updateProfile({
        context: { clientName: "rails-api" },
        variables: {
          attributes: {
            healthHistory
          }
        }
      }),
    [healthHistory]
  );

  return {
    loginAndCreateUser,
    createUser,
    updateUser,
    isLoading: isCreateUserLoading || isUpdateProfileLoading
  };
};

export { useIntakeCreateUser };
