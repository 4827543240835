import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState
} from "react";

const OverlayOpenContext = createContext<string | null>(null);
const UpdateOverlayOpenContext = createContext<
  Dispatch<SetStateAction<string | null>>
>(() => {
  throw new Error("UpdateOverlayOpenContext not implemented");
});

export function OverlayOpenProvider({ children }: { children: ReactNode }) {
  const [overlayLocation, setOverlayLocation] = useState<string | null>(null);
  return (
    <UpdateOverlayOpenContext.Provider value={setOverlayLocation}>
      <OverlayOpenContext.Provider value={overlayLocation}>
        {children}
      </OverlayOpenContext.Provider>
    </UpdateOverlayOpenContext.Provider>
  );
}

export function useUpdateOverlayOpen() {
  return useContext(UpdateOverlayOpenContext);
}

export function useOverlayOpen() {
  return useContext(OverlayOpenContext);
}
