import {
  Taskable,
  WorkoutProgram,
  WorkoutProgramDay,
  WorkoutProgramDayTask
} from "graphql/rails-api";
import {
  ComponentActivityActivityStep,
  Enum_Subscriptionplan_Interval,
  Enum_Subscriptionplan_Plantype,
  ExerciseSequence,
  SubscriptionPlan
} from "graphql/strapi-cms";
import { PatientWorkoutExercise } from "pages/patients/usePatientWorkoutPage";

const createWorkoutProgramSkeleton = (weeks: number): WorkoutProgram => {
  const days: WorkoutProgramDay[] = [];
  let dayCount = 1;

  Array.from(Array(weeks).keys()).forEach((week) => {
    days.push({
      weekNumber: week + 1,
      dayNumber: dayCount
    } as WorkoutProgramDay);

    days.push({
      weekNumber: week + 1,
      dayNumber: dayCount + 1
    } as WorkoutProgramDay);

    days.push({
      weekNumber: week + 1,
      dayNumber: dayCount + 2
    } as WorkoutProgramDay);

    dayCount += 3;
  });

  const program = { weeks, days } as WorkoutProgram;

  return program;
};

const createWorkoutProgramDayTasksSkeleton = (
  count: number
): WorkoutProgramDayTask[] =>
  Array.from(Array(count).keys()).map(() =>
    createWorkoutProgramDayTaskSkeleton()
  );

const createWorkoutProgramDayTaskSkeleton = (): WorkoutProgramDayTask =>
  ({
    taskable: {
      slug: "",
      __typename: "Workout"
    } as Taskable,
    day: {} as WorkoutProgramDay
  }) as WorkoutProgramDayTask;

const createExerciseContentSkeleton = (count: number): ExerciseSequence[] =>
  Array.from(Array(count).keys()).map(() => ({}) as ExerciseSequence);

const createPatientWorkoutExerciseSkeleton = (
  count: number
): PatientWorkoutExercise[] =>
  Array.from(Array(count).keys()).map(
    () =>
      ({
        exercise: {} as ExerciseSequence
      }) as PatientWorkoutExercise
  );

const createActivityStepSkeleton = (): ComponentActivityActivityStep => ({
  id: "",
  title: "",
  description: "",
  component: ""
});

const createSubscriptionPlanSkeleton = () =>
  ({
    name: "",
    slug: "",
    trialDuration: 0,
    interval: Enum_Subscriptionplan_Interval.Monthly,
    planType: Enum_Subscriptionplan_Plantype.Starter,
    stripePriceId: "",
    planFeatures: [{ text: "" }, { text: "" }, { text: "" }, { text: "" }]
  }) as SubscriptionPlan;

export {
  createActivityStepSkeleton,
  createExerciseContentSkeleton,
  createPatientWorkoutExerciseSkeleton,
  createSubscriptionPlanSkeleton,
  createWorkoutProgramDayTaskSkeleton,
  createWorkoutProgramDayTasksSkeleton,
  createWorkoutProgramSkeleton
};
