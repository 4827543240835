import { Badge, Button } from "@getwellen/valesco";
import {
  BookOpenIcon,
  ClockIcon,
  HomeIcon,
  UserIcon
} from "@heroicons/react/24/outline";
import { ChartBarIcon } from "@heroicons/react/24/solid";
import ChatIcon from "assets/icons/ChatIcon";
import React from "react";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { LoadingLink } from "./NavSkeletons";

const NavLinks: React.FC<{
  isLoading: boolean;
  trialDaysRemaining?: number;
  isMinimized?: boolean;
  onMyAccountClick(): void;
  anyAssessmentsComplete: boolean;
  userId?: string;
}> = ({
  isLoading = false,
  trialDaysRemaining = 0,
  isMinimized = false,
  onMyAccountClick,
  anyAssessmentsComplete = false,
  userId
}) => (
  <ul
    className={twMerge(
      "mb-10 flex w-full flex-col border-b border-cararra-600 pb-10 font-semibold",
      isMinimized ? "items-center" : "items-start"
    )}
  >
    {trialDaysRemaining >= 0 && !isLoading && !isMinimized && (
      <li className="mb-4 w-full">
        <Badge
          className="-ml-5 h-10 bg-geebung-500 font-semibold text-white"
          variant="none"
        >
          <ClockIcon className="mr-2 size-6" />
          {trialDaysRemaining} days left in trial
        </Badge>
      </li>
    )}
    <li className="mb-2 w-full">
      {isLoading ? (
        <LoadingLink />
      ) : (
        <Link
          className={twMerge("flex flex-row items-center")}
          to={"/dashboard"}
        >
          <Button
            action="secondary"
            className={twMerge(
              "w-full justify-start pl-1 text-left",
              isMinimized ? "justify-center p-0" : ""
            )}
            variant="minimal"
          >
            <HomeIcon
              className={twMerge("h-6 w-6", isMinimized ? "" : "mr-2")}
            />
            {!isMinimized && "Home"}
          </Button>
        </Link>
      )}
    </li>
    {anyAssessmentsComplete && (
      <li className="mb-2 w-full">
        {isLoading ? (
          <LoadingLink />
        ) : (
          <Link
            className={twMerge("flex flex-row items-center")}
            to={"/my-assessments"}
          >
            <Button
              action="secondary"
              className={twMerge(
                "w-full justify-start pl-1 text-left",
                isMinimized ? "justify-center p-0" : ""
              )}
              variant="minimal"
            >
              <ChartBarIcon
                className={twMerge(" w-6", isMinimized ? "mr-0" : "mr-2")}
              />
              {!isMinimized && "My Assessments"}
            </Button>
          </Link>
        )}
      </li>
    )}
    <li className="mb-2 w-full">
      {isLoading ? (
        <LoadingLink width="50%" />
      ) : (
        <Button
          action="secondary"
          className={twMerge(
            "w-full justify-start pl-1 text-left",
            isMinimized ? "justify-center p-0" : ""
          )}
          onClick={onMyAccountClick}
          variant="minimal"
        >
          <UserIcon className={twMerge("h-6 w-6", isMinimized ? "" : "mr-2")} />
          {!isMinimized && "My Account"}
        </Button>
      )}
    </li>
    <li className="mb-2 w-full">
      {isLoading ? (
        <LoadingLink />
      ) : (
        <Button
          action="secondary"
          className={twMerge(
            "w-full justify-start pl-1 text-left",
            isMinimized ? "justify-center p-0" : ""
          )}
          variant="minimal"
        >
          <a
            className="flex flex-row items-center"
            href="https://www.getwellen.com/well-guide"
            rel="noreferrer"
            target="_blank"
          >
            <BookOpenIcon
              className={twMerge("h-6 w-6", isMinimized ? "" : "mr-2")}
            />
            {!isMinimized && "Well Guide"}
          </a>
        </Button>
      )}
    </li>
    <li className="w-full">
      {isLoading ? (
        <LoadingLink />
      ) : (
        <Button
          action="secondary"
          className={twMerge(
            "w-full justify-start pl-1 text-left",
            isMinimized ? "justify-center p-0" : ""
          )}
          variant="minimal"
        >
          <a
            className="flex flex-row items-center"
            href={
              (window.location.hostname.startsWith("app-qa")
                ? "https://chat-qa.getwellen.com"
                : window.location.hostname.startsWith("app")
                  ? "https://chat.getwellen.com"
                  : "http://localhost:3003") +
              (userId ? "?userId=" + userId : "")
            }
            rel="noreferrer"
            target="_blank"
          >
            <ChatIcon
              className={twMerge("h-6 w-6", isMinimized ? "" : "mr-2")}
            />
            {!isMinimized && "Chat"}
          </a>
        </Button>
      )}
    </li>
  </ul>
);

export { NavLinks };
