import { TextInput } from "@getwellen/valesco";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import React, { useState } from "react";
import { FieldError } from "react-hook-form";
import { twMerge } from "tailwind-merge";
import { errorHelpText } from "utils/forms";

interface PasswordInput {
  className?: string;
  label: string;
  value: string;
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
  onBlur(event: React.ChangeEvent<HTMLInputElement>): void;
  error?: FieldError;
  autoComplete?: string;
}

const PasswordInput: React.FC<PasswordInput> = ({
  className = "",
  label,
  value,
  onChange,
  onBlur,
  error = undefined,
  autoComplete = "current-password"
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={twMerge("relative", className)}>
      <TextInput
        autoComplete={autoComplete}
        error={!!error}
        helpText={errorHelpText(error)}
        isPassword={!showPassword}
        label={label}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
      />
      <button
        className={twMerge(
          "absolute right-3 h-6 w-6 opacity-50",
          !!error ? "top-10" : "top-1/2"
        )}
        onClick={(e) => {
          e.preventDefault();

          setShowPassword(!showPassword);
        }}
        tabIndex={-1}
        type="button"
      >
        {showPassword ? <EyeIcon /> : <EyeSlashIcon />}
      </button>
    </div>
  );
};

export { PasswordInput };
