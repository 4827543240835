import React from "react";

export default function ChatIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      height={24}
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 0 1-.923 1.785A5.969 5.969 0 0 0 6 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337Z"
        stroke="#334C61"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
      <g
        clipPath="url(#a)"
        clipRule="evenodd"
        fill="#334C61"
        fillRule="evenodd"
      >
        <path d="M10.5 8.25c.167 0 .315.111.36.272l.407 1.423c.178.623.665 1.11 1.288 1.288l1.423.406a.375.375 0 0 1 0 .722l-1.423.406a1.875 1.875 0 0 0-1.288 1.288l-.406 1.423a.375.375 0 0 1-.722 0l-.406-1.423a1.875 1.875 0 0 0-1.288-1.288l-1.423-.406a.375.375 0 0 1 0-.722l1.423-.406a1.875 1.875 0 0 0 1.288-1.288l.406-1.423a.375.375 0 0 1 .361-.272ZM15 6.75c.172 0 .322.117.364.284l.13.518c.117.47.484.837.954.955l.518.13a.375.375 0 0 1 0 .727l-.518.13c-.47.117-.837.484-.955.954l-.13.518a.375.375 0 0 1-.727 0l-.13-.518a1.313 1.313 0 0 0-.954-.955l-.518-.13a.375.375 0 0 1 0-.727l.518-.13c.47-.117.837-.484.955-.954l.13-.518A.375.375 0 0 1 15 6.75ZM14.25 13.5c.161 0 .305.103.356.256l.197.592c.074.224.25.4.474.474l.592.197a.375.375 0 0 1 0 .712l-.592.197a.75.75 0 0 0-.474.474l-.197.592a.375.375 0 0 1-.712 0l-.197-.592a.75.75 0 0 0-.474-.474l-.592-.197a.375.375 0 0 1 0-.712l.592-.197a.75.75 0 0 0 .474-.474l.197-.592a.375.375 0 0 1 .356-.256Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path d="M6 6h12v12H6z" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
}
