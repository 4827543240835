import { Select, TextInput } from "@getwellen/valesco";
import {
  DefaultPrescriberForm,
  PrescriberAddressKey,
  PrescriberFormType,
  useOsteoboostOrder
} from "contexts/OsteoboostOrderContext";
import { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  errorHelpText,
  formatPhoneNumber,
  stateOptions,
  unformatPhoneNumber,
  validatePhoneNumber,
  validateZipcode,
  validNamePattern
} from "utils/forms";

import { FormStepButtons, FormStepChildProps } from "./FormStep";

type PrescriberFormProps = FormStepChildProps;

export const PrescriberForm = ({
  showBack,
  onBack,
  onSubmit
}: PrescriberFormProps) => {
  const { isLoading, order, updateOrder } = useOsteoboostOrder();
  const formValues = order[PrescriberAddressKey];
  const { control, errors, getValues, isValid } = usePrescriberForm(formValues);

  // CALLBACKS
  const handleSubmit = useCallback(() => {
    const values = getValues();
    // Clean the phone number by removing formatting characters
    const cleanedPhone = unformatPhoneNumber(values.phone);
    const cleanedFax = unformatPhoneNumber(values.fax);
    const cleanedValues = { ...values, phone: cleanedPhone, fax: cleanedFax };
    updateOrder(PrescriberAddressKey, cleanedValues, onSubmit);
  }, [onSubmit]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-8 grid grid-cols-1 gap-8">
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-3">
          <Controller
            control={control}
            name="firstName"
            render={({ field: { onChange, onBlur, value } }) => (
              <TextInput
                error={!!errors.firstName}
                helpText={errorHelpText(errors.firstName)}
                label="Prescriber’s first name*"
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
            rules={{
              required: true,
              pattern: validNamePattern,
              maxLength: {
                value: 100,
                message: "Must be less than 100 characters"
              }
            }}
          />
          <Controller
            control={control}
            name="lastName"
            render={({ field: { onChange, onBlur, value } }) => (
              <TextInput
                error={!!errors.lastName}
                helpText={errorHelpText(errors.lastName)}
                label="Prescriber’s last name*"
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
            rules={{
              required: true,
              pattern: validNamePattern,
              maxLength: {
                value: 100,
                message: "Must be less than 100 characters"
              }
            }}
          />
        </div>
        <Controller
          control={control}
          name="address1"
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              error={!!errors.address1}
              helpText={errorHelpText(errors.address1)}
              label="Address*"
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            />
          )}
          rules={{ required: true }}
        />
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-3">
          <Controller
            control={control}
            name="city"
            render={({ field: { onChange, onBlur, value } }) => (
              <TextInput
                error={!!errors.city}
                helpText={errorHelpText(errors.city)}
                label="City*"
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
            rules={{ required: true }}
          />
          <Controller
            control={control}
            name="state"
            render={({ field: { onChange, onBlur, value } }) => (
              <Select
                error={!!errors.state}
                helpText={errorHelpText(errors.state)}
                label="State*"
                onBlur={onBlur}
                onChange={onChange}
                options={stateOptions}
                value={value}
              />
            )}
            rules={{ required: true }}
          />
        </div>
        <Controller
          control={control}
          name="zipcode"
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              error={!!errors.zipcode}
              helpText={errorHelpText(errors.zipcode)}
              label="Zip code*"
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            />
          )}
          rules={{
            required: true,
            validate: validateZipcode
          }}
        />
        <Controller
          control={control}
          name="phone"
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              error={!!errors.phone}
              helpText={errorHelpText(errors.phone)}
              label="Day phone*"
              onBlur={onBlur}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const input = e.target.value;
                const formatted = formatPhoneNumber(input);
                onChange(formatted);
              }}
              value={value}
            />
          )}
          rules={{
            required: true,
            validate: validatePhoneNumber
          }}
        />
        <Controller
          control={control}
          name="fax"
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              error={!!errors.fax}
              helpText={errorHelpText(errors.fax)}
              label="Fax"
              onBlur={onBlur}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const input = e.target.value;
                const formatted = formatPhoneNumber(input);
                onChange(formatted);
              }}
              value={value}
            />
          )}
          rules={{
            required: false,
            validate: validatePhoneNumber
          }}
        />
      </div>

      <FormStepButtons
        isFinal={true}
        isValid={isValid}
        loading={isLoading}
        onBack={onBack}
        onNext={handleSubmit}
        showBack={showBack}
      />
    </form>
  );
};

const usePrescriberForm = (formValues = DefaultPrescriberForm) => {
  const {
    control,
    getValues,
    formState: { errors, isValid }
  } = useForm<PrescriberFormType>({
    mode: "onBlur",
    defaultValues: formValues
  });

  return {
    control,
    getValues,
    isValid,
    errors
  };
};
