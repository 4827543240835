import {
  AppBar,
  AppBarVariant,
  Button,
  Footer,
  Sidebar
} from "@getwellen/valesco";
import { Transition } from "@headlessui/react";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/solid";
import { Nav } from "components/nav";
import { PaymentFailureBanner } from "components/payments/PaymentFailureBanner";
import { StreamingErrorBanner } from "components/workout/StreamingErrorBanner";
import { useEnv } from "contexts";
import { useCurrentUser } from "contexts/CurrentUserContext";
import { isBreakpoint } from "hooks/useBreakpoint";
import React, { memo, useEffect, useState } from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import { Outlet } from "react-router-dom";

const Main: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { SHOW_STREAMING_ERROR } = useEnv();

  const [isDrawerOpen, setIsDrawerOpen] = useState(isBreakpoint("md"));
  // state triggered only once for the burger, prevents animation running on page load
  const [burgerOnce, setBurgerOnce] = useState(false);

  useEffect(() => {
    isDrawerOpen === true && setBurgerOnce(true);
  }, [isDrawerOpen]);

  const { currentUserIsPaymentFailure } = useCurrentUser();

  return (
    <>
      <div className="flex flex-col bg-cararra-200 pt-14 md:pt-0">
        <header className="md:hidden">
          <AppBar
            burgerActive={isDrawerOpen}
            burgerOnce={burgerOnce}
            hasMenu={true}
            onMenuClick={() => setIsDrawerOpen(!isDrawerOpen)}
            variant={AppBarVariant.Light}
          ></AppBar>
        </header>
        <div className="flex flex-row">
          {!isBreakpoint("md") ? (
            <MobileSidebar isDrawerOpen={isDrawerOpen} />
          ) : (
            <DesktopSidebar
              isDrawerOpen={isDrawerOpen}
              setIsDrawerOpen={setIsDrawerOpen}
            />
          )}
          <div
            className={`flex flex-1 flex-col ${
              !isBreakpoint("md") && isDrawerOpen && "fixed overflow-hidden"
            }`}
          >
            {!currentUserIsPaymentFailure && SHOW_STREAMING_ERROR && (
              <StreamingErrorBanner />
            )}
            {currentUserIsPaymentFailure && <PaymentFailureBanner />}
            <main className="mx-auto min-h-screen w-full max-w-7xl p-4 py-8 md:h-full md:p-16 lg:px-24 lg:py-20">
              {children || <Outlet />}
            </main>
            <footer className="mt-auto">
              <Footer />
            </footer>
          </div>
        </div>
      </div>
    </>
  );
};

const MobileSidebar: React.FC<{ isDrawerOpen: boolean }> = ({
  isDrawerOpen
}) => (
  <Transition
    appear={true}
    className="fixed inset-0 top-14 z-10 size-full"
    enter="transition-left duration-500 ease-out"
    enterFrom="left-full"
    enterTo="left-0"
    leave="transition-left duration-500 ease-in"
    leaveFrom="left-0"
    leaveTo="left-full"
    show={isDrawerOpen}
  >
    <aside className="block size-full bg-cararra-500 shadow-[1px_0px_3px_rgba(0,0,0,0.1),_1px_0px_2px_-1px_rgba(0,0,0,0.1)]">
      <SkeletonTheme baseColor="#BEBDB9" highlightColor="#EEECE7">
        <Sidebar className="overflow-scroll pt-8 md:overflow-visible md:pt-10">
          <Nav />
        </Sidebar>
      </SkeletonTheme>
    </aside>
  </Transition>
);

const DesktopSidebar: React.FC<{
  isDrawerOpen: boolean;
  setIsDrawerOpen(isDrawerOpen: boolean): void;
}> = ({ isDrawerOpen, setIsDrawerOpen }) => (
  <aside className="inset-0 bg-cararra-500 shadow-[1px_0px_3px_rgba(0,0,0,0.1),_1px_0px_2px_-1px_rgba(0,0,0,0.1)] md:sticky md:top-0 md:w-auto">
    <SkeletonTheme baseColor="#BEBDB9" highlightColor="#EEECE7">
      {isDrawerOpen && (
        <Sidebar className="overflow-scroll pt-10 md:overflow-visible md:pt-10">
          <Nav
            onToggle={() => {
              setIsDrawerOpen(!isDrawerOpen);
            }}
          />
        </Sidebar>
      )}
      {!isDrawerOpen && (
        <Sidebar
          className="hidden pt-10 md:flex md:flex-col md:justify-start"
          isCollapsed={true}
        >
          <Button
            action="secondary"
            className="mb-10 items-center justify-center p-0"
            onClick={() => {
              setIsDrawerOpen(!isDrawerOpen);
            }}
            variant="minimal"
          >
            <ChevronDoubleRightIcon className="size-5 text-lynch-500" />
          </Button>
          <Nav isMinimized={true} />
        </Sidebar>
      )}
    </SkeletonTheme>
  </aside>
);

export default memo(Main);
