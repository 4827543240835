import { SocialButtonType } from "@getwellen/valesco";
import { useAuth } from "contexts/AuthContext";
import { useCallback } from "react";

const useSocialLogin = () => {
  const { loginSSO } = useAuth();

  const onSocialLoginClick = useCallback(
    (buttonType: SocialButtonType, redirectUri?: string) => {
      if (buttonType === SocialButtonType.Google) {
        loginSSO("google-oauth2", redirectUri);
      }
      if (buttonType === SocialButtonType.Facebook) {
        loginSSO("facebook", redirectUri);
      }
    },
    [loginSSO]
  );

  return {
    onSocialLoginClick
  };
};

export { useSocialLogin };
