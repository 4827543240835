import React from "react";
import { Outlet } from "react-router-dom";

import { useOnboardingRoute } from "./useOnboardingRoute";

const OnboardingRoute: React.FC = () => {
  useOnboardingRoute();

  return <Outlet />;
};

export default OnboardingRoute;
