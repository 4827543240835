import {
  Overlay as OverlayComponent,
  OverlaySize,
  OverlayTheme,
  OverlayWithoutFrame
} from "@getwellen/valesco";
import { useUpdateOverlayOpen } from "contexts/OverlayOpenContext";
import React, { memo, useEffect } from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { LocationState } from "utils/location";

const Overlay: React.FC<{
  children?: React.ReactNode;
  dismissable?: boolean;
  size?: OverlaySize;
  withoutFrame?: boolean;
  theme?: OverlayTheme;
}> = ({
  children,
  dismissable = true,
  size = OverlaySize.Small,
  theme = OverlayTheme.Dark,
  withoutFrame = false
}) => {
  const updateOverlayOpen = useUpdateOverlayOpen();
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as LocationState;

  const handleCloseOverlay = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.stopPropagation();

    if (!dismissable) return;

    updateOverlayOpen(null);

    // if we have a background location, we want to bring the user back
    // when they dismiss an overlay (e.g click behind it or the X icon).
    if (
      locationState?.backgroundLocation?.pathname &&
      !locationState?.isOverlayAlreadyOpen
    ) {
      navigate(locationState?.backgroundLocation?.pathname, { replace: true });
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    // indicate that overlay is open on mount
    updateOverlayOpen(location.pathname);

    // indicate that overlay is closed on unmount
    return () => {
      updateOverlayOpen(null);
    };
  }, [location.pathname]);

  return (
    <SkeletonTheme baseColor="#BEBDB9" highlightColor="#EEECE7">
      {withoutFrame && (
        <OverlayWithoutFrame
          onClose={dismissable ? handleCloseOverlay : undefined}
          size={size}
          theme={theme}
        >
          {children || <Outlet />}
        </OverlayWithoutFrame>
      )}
      {!withoutFrame && (
        <OverlayComponent
          onClose={dismissable ? handleCloseOverlay : undefined}
          size={size}
          theme={theme}
        >
          {children || <Outlet />}
        </OverlayComponent>
      )}
    </SkeletonTheme>
  );
};

export default memo(Overlay);
