import { twMerge } from "tailwind-merge";

export type OrderTitleProps = {
  title?: string;
  subtitle?: string;
  titleStyles?: string;
  subtitleStyles?: string;
};

const defaultTitle = "Thank you for your order!";
const defaultSubtitle =
  "Here’s an overview of your order details and next steps:";

export const OrderTitle = ({
  title = defaultTitle,
  subtitle = defaultSubtitle,
  titleStyles,
  subtitleStyles
}: OrderTitleProps) => {
  return (
    <div className="grid grid-cols-1 gap-8">
      <h1
        className={twMerge("font-display text-6xl leading-[4rem]", titleStyles)}
      >
        {title}
      </h1>
      <h3
        className={twMerge(
          "font-heading text-xl font-semibold",
          subtitleStyles
        )}
      >
        {subtitle}
      </h3>
    </div>
  );
};
