import { IntakePageData } from "contexts/IntakeContext";
import { ProfileItemAttributes } from "graphql/rails-api";
import {
  ComponentContentAccountSetup,
  SubscriptionPlan
} from "graphql/strapi-cms";
import { Location } from "react-router-dom";

//
// @note this is turning into something that might be better
// solved with redux or a global state management solve (e.g another
// react contexdt)...
//
export interface LocationState {
  // the background location when opening an overlay
  backgroundLocation?: Location;
  // whether or not an overlay is already open
  accountSetup?: ComponentContentAccountSetup;
  currentPlanInterval?: SubscriptionPlan["interval"];
  currentPlanPlanType?: SubscriptionPlan["planType"];
  currentSubscriptionId?: string;
  disclaimer?: string;
  healthDisclaimer?: string;
  intake?: IntakePageData;
  isOverlayAlreadyOpen?: boolean;
  openOverlay?: string;
  returnTo?: string;
  selectedPlan?: SubscriptionPlan;
  updatedFitnessProfileAttrs?: ProfileItemAttributes[];
}

/**
 * resolveBackgroundLocation
 *
 * This utility will either set the current location as
 * our background or it will look for existing background state
 * and set that as the background location
 *
 * @param location
 */
const resolveBackgroundLocation = (location: Location) => {
  const state = location.state as { backgroundLocation?: Location };

  if (!state?.backgroundLocation) return location;

  return state.backgroundLocation;
};

export { resolveBackgroundLocation };
