import { FetchResult } from "@apollo/client";
import {
  useValidatePromoCodeMutation,
  ValidatePromoCodeMutation
} from "graphql/rails-api";
import {
  SubscriptionPlan,
  useSubscriptionPlansQuery
} from "graphql/strapi-cms";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useLocalStorage } from "react-use";

import { useGeolocation } from "./GeolocationContext";

interface PromoCodeContextInterface {
  promoCode: string;
  stripePriceId: string;
  clearAutoApplyPromoCode: () => void;
  setPromoCode: React.Dispatch<React.SetStateAction<string>>;
  setStripePriceId: React.Dispatch<React.SetStateAction<string>>;
  validatePromoCode(): Promise<FetchResult<ValidatePromoCodeMutation>>;
  autoApplyPromoCode: AutoApplyCouponCode | undefined;
}

const defaultState: PromoCodeContextInterface = {
  promoCode: "",
  stripePriceId: "",
  clearAutoApplyPromoCode: () => null,
  setPromoCode: () => null,
  setStripePriceId: () => null,
  validatePromoCode: async () => ({}) as FetchResult<ValidatePromoCodeMutation>,
  autoApplyPromoCode: undefined
};

const PromoCodeContext = createContext<PromoCodeContextInterface>(defaultState);

type AutoApplyCouponCode = {
  isValid: boolean;
  stripePriceId: string;
  promoCode: string;
};

const PromoCodeProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [autoApplyPromoCode, setAutoApplyPromoCode] = useLocalStorage<
    AutoApplyCouponCode[]
  >("wellen.autoApplyPromoCode", []);
  const [promoCode, setPromoCode] = useState<string>("");
  const [stripePriceId, setStripePriceId] = useState<string>("");
  const [_validatePromoCode] = useValidatePromoCodeMutation();
  const { country_code } = useGeolocation();
  const location = useLocation();
  const [params] = useSearchParams();

  const { data: plansData, loading: plansIsLoading } =
    useSubscriptionPlansQuery({
      fetchPolicy: "cache-first"
    });

  const plans = (plansData?.subscriptionPlans?.data || []).map(
    (p) => p.attributes as SubscriptionPlan
  );

  const validatePromoCode = useCallback(
    () =>
      _validatePromoCode({
        context: { clientName: "rails-api" },
        variables: {
          code: promoCode,
          stripePriceId,
          countryCode: country_code
        }
      }),
    [_validatePromoCode, promoCode, location]
  );

  const validatePromoCodes = async (promoCode: string) => {
    setAutoApplyPromoCode(
      await Promise.all(
        plans.map(async (plan) => {
          const result = await _validatePromoCode({
            context: { clientName: "rails-api" },
            variables: {
              code: promoCode,
              countryCode: country_code,
              stripePriceId: plan.stripePriceId
            }
          });
          return {
            promoCode,
            isValid: !!result.data?.validatePromoCode.isValid,
            stripePriceId: plan.stripePriceId
          };
        })
      )
    );
  };

  const clearAutoApplyPromoCode = () => {
    setAutoApplyPromoCode([]);
  };

  useEffect(() => {
    const promoCode: string | null = params.get("promo_code");

    // url based promo codes
    if (promoCode && !plansIsLoading) {
      void validatePromoCodes(promoCode);
    }
  }, [location, plansIsLoading]);

  useEffect(() => {
    const autoApply = (autoApplyPromoCode || []).find(
      (p) => p.isValid && p.stripePriceId === stripePriceId
    );

    if (autoApply) setPromoCode(autoApply.promoCode);
  }, [stripePriceId]);

  return (
    <PromoCodeContext.Provider
      value={{
        promoCode,
        stripePriceId,
        setPromoCode,
        setStripePriceId,
        validatePromoCode,
        clearAutoApplyPromoCode,
        autoApplyPromoCode: (autoApplyPromoCode || []).find(
          (p) => p.stripePriceId === stripePriceId
        )
      }}
    >
      {children}
    </PromoCodeContext.Provider>
  );
};

const usePromoCode = () => {
  const context = useContext(PromoCodeContext);

  if (!context)
    throw new Error("usePromoCode must be used within PromoCodeProvider");

  return context;
};

export { PromoCodeProvider, usePromoCode };
