import React, { createContext, useContext, useState } from "react";

interface ModalContext {
  modalContent: React.ReactNode;
  setModalContent: React.Dispatch<React.SetStateAction<React.ReactNode>>;
}

const defaultState: ModalContext = {
  modalContent: null,
  setModalContent: () => null
};

const ModalContext = createContext<ModalContext>(defaultState);

const ModalProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [modalContent, setModalContent] = useState<React.ReactNode>(null);

  return (
    <ModalContext.Provider
      value={{
        modalContent,
        setModalContent
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

const useModal = () => {
  const context = useContext(ModalContext);

  if (!context) throw new Error("useModal must be used within ModalContext");

  return context;
};

export { ModalContext, ModalProvider, useModal };
