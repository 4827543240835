import { Radio } from "@getwellen/valesco";
import {
  DefaultPrescriptionForm,
  PrescriptionFormType,
  PrescriptionKey,
  useOsteoboostOrder
} from "contexts/OsteoboostOrderContext";
import { Prescription } from "graphql/rails-api";
import { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";

import { FormStepButtons, FormStepChildProps } from "./FormStep";

const prescriptionOptions = [
  {
    label:
      "My doctor will call, fax, or e-prescribe (call to: 800-748-7001 or fax to: 1-888-870-2808)",
    value: Prescription.PrescriberWillContact
  },
  {
    label: "I will mail my written prescription",
    value: Prescription.UserWillContact
  }
];

type PrescriptionFormProps = FormStepChildProps;

export const PrescriptionForm = ({
  showBack,
  onBack,
  onSubmit
}: PrescriptionFormProps) => {
  const { isLoading, order, updateOrder } = useOsteoboostOrder();
  const { control, getValues, setError, isValid } = usePrescriptionForm(
    order[PrescriptionKey]
  );

  // CALLBACKS
  const handleSubmit = useCallback(() => {
    const values = getValues();
    // Custom validation logic
    if (!values.prescriptionType) {
      setError("prescriptionType", {
        type: "manual",
        message: "Please select at least one option."
      });
      return;
    }
    updateOrder(PrescriptionKey, values, onSubmit);
  }, [onSubmit]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-8 grid grid-cols-1 gap-3">
        {prescriptionOptions.map((option) => (
          <Controller
            key={option.value}
            control={control}
            name="prescriptionType"
            render={({ field: { onChange, value } }) => (
              <Radio
                checked={value === option.value}
                label={option.label}
                onChange={onChange}
                value={option.value}
              />
            )}
          />
        ))}
      </div>

      <FormStepButtons
        isValid={isValid}
        loading={isLoading}
        onBack={onBack}
        onNext={handleSubmit}
        showBack={showBack}
      />
    </form>
  );
};

const usePrescriptionForm = (formValues = DefaultPrescriptionForm) => {
  const {
    control,
    getValues,
    setError,
    formState: { isValid, errors }
  } = useForm<PrescriptionFormType>({
    mode: "onChange",
    defaultValues: formValues
  });

  return {
    errors,
    setError,
    control,
    getValues,
    isValid
  };
};
