import React from "react";

import { LoadingSkeleton } from "./LoadingSkeleton";

const LoadingSkeletonIcon: React.FC<{
  className?: string;
  containerClassName?: string;
}> = ({ className = "mr-3", containerClassName }) => (
  <LoadingSkeleton
    circle={true}
    className={className}
    containerClassName={containerClassName}
    height="24px"
    width="24px"
  />
);

export { LoadingSkeletonIcon };
