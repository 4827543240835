import { notify } from "@getwellen/valesco";
import { useAuth } from "contexts/AuthContext";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { translateAuthErorr } from "utils/text";

const useAuthError = () => {
  const [searchParams] = useSearchParams();
  const { authError: auth0AuthError } = useAuth();
  const [prevError, setPrevError] = useState<string | undefined>();
  const authError = searchParams.get("authError") || auth0AuthError;

  useEffect(() => {
    if (
      authError &&
      authError !== "login_required" &&
      authError !== prevError
    ) {
      setPrevError(authError);
      notify.error(translateAuthErorr(authError), {
        duration: 5000
      });
    }
  }, []);
};

export { useAuthError };
