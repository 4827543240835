import { Button } from "@getwellen/valesco";
import {
  ArrowLeftOnRectangleIcon,
  PencilSquareIcon
} from "@heroicons/react/24/outline";
import React from "react";
import { twMerge } from "tailwind-merge";

import { LoadingLink } from "./NavSkeletons";

interface NavActions {
  isMinimized?: boolean;
  onLogout(): void;
  onUpdateProfileClick(): void;
  isLoading?: boolean;
}

const NavActions: React.FC<NavActions> = ({
  isMinimized = false,
  onUpdateProfileClick,
  onLogout,
  isLoading = false
}) => (
  <ul
    className={twMerge(
      "font-semibold",
      isMinimized ? "flex flex-col items-center" : ""
    )}
  >
    <li className="mb-2">
      {isLoading ? (
        <LoadingLink />
      ) : (
        <Button
          action="secondary"
          className={twMerge(
            "w-full justify-start pl-1",
            isMinimized ? "justify-center p-0" : ""
          )}
          onClick={onUpdateProfileClick}
          variant="minimal"
        >
          <PencilSquareIcon
            className={twMerge("h-6 w-6", isMinimized ? "" : "mr-2")}
          />
          {!isMinimized && "Update Profile"}
        </Button>
      )}
    </li>
    <li className="mb-2">
      {isLoading ? (
        <LoadingLink />
      ) : (
        <Button
          action="secondary"
          className={twMerge(
            "w-full justify-start pl-1",
            isMinimized ? "justify-center p-0" : ""
          )}
          onClick={onLogout}
          variant="minimal"
        >
          <ArrowLeftOnRectangleIcon
            className={"h-6 w-6 rotate-180 " + (isMinimized ? "" : "mr-2")}
          />
          {!isMinimized && "Logout"}
        </Button>
      )}
    </li>
  </ul>
);

export { NavActions };
