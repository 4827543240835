import { Outlet } from "react-router-dom";

type Quiz = {
  children?: React.ReactNode;
};

export default function Quiz({ children = undefined }: Quiz) {
  return (
    <div className="h-screen w-full bg-squiggle bg-repeat bg-cover flex items-center justify-center">
      {children || <Outlet />}
    </div>
  );
}
