import { useEffect } from "react";

import useHostname, { Domain } from "./useHostname";

const OsteoboostTitle = "Osteoboost - Comprehensive bone health solution";
const WellenTitle = "Wellen - Personalized exercise program for osteoporosis";

const useWindowTitle = () => {
  const hostname = useHostname();
  useEffect(() => {
    const domainTitleMap: { [key: string]: string } = {
      [Domain.Osteoboost]: OsteoboostTitle,
      [Domain.Wellen]: WellenTitle
    };

    // Set the title based on the hostname, default if not found
    document.title = domainTitleMap[hostname] || WellenTitle;
  }, []);
};

export default useWindowTitle;
