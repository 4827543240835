import { Station, WorkoutMix } from "@getwellen/valesco";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocalStorage } from "react-use";

import { useCurrentUser } from "./CurrentUserContext";

export const mixes: WorkoutMix[] = [
  { voice: 0.75, music: 0.75, name: "Balanced" },
  { voice: 0.5, music: 1, name: "Music" },
  { voice: 1, music: 0.5, name: "Voice" }
];

type MusicContext = {
  mix: WorkoutMix;
  station: string;
  stations: Station[];
  setMix: (mix: WorkoutMix) => void;
  setStation: (station: string) => void;
  setStations: (stations: Station[]) => void;
};

const MusicContext = createContext<MusicContext>({
  mix: mixes[0],
  station: "Pop",
  stations: [],
  setMix: () => null,
  setStation: () => null,
  setStations: () => null
});

const useMusic = () => useContext(MusicContext);

const MusicProvider = ({ children }: { children: React.ReactNode }) => {
  const [localMix, setLocalMix] = useLocalStorage<WorkoutMix>(
    "wellen.mix",
    mixes[0]
  );
  const [localStation, setLocalStation] = useLocalStorage<string>(
    "wellen.station",
    "Pop"
  );
  const { currentUser } = useCurrentUser();
  const [mix, setMix] = useState<WorkoutMix>(() => {
    return (
      mixes.find((m) => m.name === currentUser?.musicPreferences?.mix) ||
      localMix ||
      mixes[0]
    );
  });
  const [station, setStation] = useState<string>(() => {
    return currentUser?.musicPreferences?.station || localStation || "Pop";
  });
  const [stations, setStations] = useState<Station[]>([]);

  useEffect(() => {
    setLocalMix(mix);
  }, [mix]);

  useEffect(() => {
    setLocalStation(station);
  }, [station]);

  return (
    <MusicContext.Provider
      value={{
        mix,
        setMix,
        setStation,
        setStations,
        station,
        stations
      }}
    >
      {children}
    </MusicContext.Provider>
  );
};

export { MusicProvider, useMusic };
