import { useAuth } from "contexts/AuthContext";
import { useCurrentUser } from "contexts/CurrentUserContext";
import {
  Profile,
  useGetCurrentUserProfileQuery,
  useGetCurrentUserSubscriptionQuery
} from "graphql/rails-api";
import {
  Quiz,
  useFitnessProfileQuizWithoutVideoQuery
} from "graphql/strapi-cms";
import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LocationState, resolveBackgroundLocation } from "utils/location";

import { Nav as PresentationalNav } from "./Nav";

const Nav: React.FC<{
  onToggle?(): void;
  isMinimized?: boolean;
}> = ({ onToggle = undefined, isMinimized = false }) => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUserIsTrialing, currentUserIsSubscribed } = useCurrentUser();
  const { currentUser } = useCurrentUser();

  const {
    client,
    data: subscriptionData,
    loading: subscriptionIsLoading
  } = useGetCurrentUserSubscriptionQuery({
    context: { clientName: "rails-api" }
  });

  const { loading: userProfileLoading, data: userProfileData } =
    useGetCurrentUserProfileQuery({
      context: { clientName: "rails-api" }
    });

  const { loading: fitnessQuizLoading, data: fitnessQuizData } =
    useFitnessProfileQuizWithoutVideoQuery();

  const handleLogoutClick = () => {
    sessionStorage.clear();

    logout({
      apolloClient: client
    });
  };

  const handleOpenFitnessProfile = () => {
    navigate("/fitness-profile/edit", {
      state: {
        backgroundLocation: resolveBackgroundLocation(location)
      } as LocationState
    });
  };

  const handleOpenMyAccount = () => {
    navigate("/my-account/achievements", {
      state: {
        backgroundLocation: resolveBackgroundLocation(location)
      } as LocationState
    });
  };

  const fitnessProfileQuiz = fitnessQuizData?.quizzes?.data?.find(
    (q) => q?.attributes?.slug === "fitness-profile"
  )?.attributes as Quiz;

  const fitnessProfile = userProfileData?.getCurrentUserProfile
    .fitness as Profile;

  const trialDaysRemaining = Number(
    subscriptionData?.getCurrentUserSubscription?.trialDaysRemaining
  );

  const navLogoProps = useMemo<
    React.ComponentProps<typeof PresentationalNav>["navLogoProps"]
  >(
    () => ({
      onToggle
    }),
    [onToggle]
  );

  const navLinkProps = useMemo<
    React.ComponentProps<typeof PresentationalNav>["navLinkProps"]
  >(
    () => ({
      trialDaysRemaining:
        currentUserIsTrialing && trialDaysRemaining >= 0
          ? trialDaysRemaining
          : NaN,
      onMyAccountClick: handleOpenMyAccount,
      isLoading: subscriptionIsLoading,
      isMinimized,
      anyAssessmentsComplete:
        currentUser?.assessmentsCompleted !== undefined &&
        currentUser?.assessmentsCompleted > 0,
      userId:
        currentUserIsTrialing || currentUserIsSubscribed
          ? (currentUser?.id as string)
          : undefined
    }),
    [
      currentUserIsTrialing,
      trialDaysRemaining,
      handleOpenMyAccount,
      subscriptionIsLoading,
      isMinimized,
      currentUser?.assessmentsCompleted,
      currentUser?.id
    ]
  );

  const navFitnessProps = useMemo<
    React.ComponentProps<typeof PresentationalNav>["navFitnessProps"]
  >(
    () => ({
      isLoading: userProfileLoading || fitnessQuizLoading,
      fitnessProfileQuiz,
      fitnessProfile
    }),
    [userProfileLoading, fitnessQuizLoading, fitnessProfileQuiz, fitnessProfile]
  );

  const navActionsProps = useMemo<
    React.ComponentProps<typeof PresentationalNav>["navActionsProps"]
  >(
    () => ({
      onLogout: handleLogoutClick,
      onUpdateProfileClick: handleOpenFitnessProfile,
      isMinimized,
      isLoading: subscriptionIsLoading
    }),
    [
      handleLogoutClick,
      handleOpenFitnessProfile,
      isMinimized,
      subscriptionIsLoading
    ]
  );

  return (
    <PresentationalNav
      isMinimized={isMinimized}
      navActionsProps={navActionsProps}
      navFitnessProps={navFitnessProps}
      navLinkProps={navLinkProps}
      navLogoProps={navLogoProps}
    />
  );
};

export { Nav };
