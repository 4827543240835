import { ExclamationTriangleIcon, XMarkIcon } from "@heroicons/react/24/solid";
import React, { useCallback, useState } from "react";

const StreamingErrorBanner: React.FC = () => {
  const [isVisible, setIsVisibile] = useState<boolean>(true);

  const onClose = useCallback(() => {
    setIsVisibile(false);
  }, []);

  if (!isVisible) return null;

  return (
    <div className="relative bg-gray-700 px-4 py-10 text-white md:px-24 md:py-10">
      <button onClick={onClose}>
        <XMarkIcon className="absolute right-4 top-4 size-6 md:right-8 md:top-8" />
      </button>
      <ExclamationTriangleIcon className="mb-2 size-8 text-geebung-200" />
      <p className="mb-4 font-semibold">
        We are experiencing intermittent issues with video streaming. We&apos;re
        on the case with our service provider to fix this as soon as possible.
        Thanks for your patience! If you have questions or need assistance,
        please email us at{" "}
        <a
          className="underline"
          href="mailto:support@getwellen.com"
          rel="noreferrer"
          target="__blank"
        >
          support@getwellen.com
        </a>
        .
      </p>
    </div>
  );
};

export { StreamingErrorBanner };
