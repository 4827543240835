import type { ComponentProps } from "react";

import { NavActions } from "../NavActions";
import { NavFitnessProfile } from "../NavFitnessProfile";
import { NavLinks } from "../NavLinks";
import { NavLogo } from "../NavLogo";

type NavProps = {
  navLogoProps: ComponentProps<typeof NavLogo>;
  navLinkProps: ComponentProps<typeof NavLinks>;
  navFitnessProps: ComponentProps<typeof NavFitnessProfile>;
  navActionsProps: ComponentProps<typeof NavActions>;
  isMinimized?: boolean;
};

const Nav = ({
  navLogoProps,
  navLinkProps,
  navFitnessProps,
  navActionsProps,
  isMinimized = false
}: NavProps) => {
  return (
    <>
      {!isMinimized && <NavLogo {...navLogoProps} />}
      <NavLinks {...navLinkProps} />
      {!isMinimized && <NavFitnessProfile {...navFitnessProps} />}
      <NavActions {...navActionsProps} />
    </>
  );
};

export { Nav };
