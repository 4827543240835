import { valescoTheme } from "@getwellen/valesco";
import { useEffect, useRef, useState } from "react";

const screens = valescoTheme.screens;

export const isBrowser = window.navigator || typeof window !== "undefined";

// https://github.com/kodingdotninja/use-tailwind-breakpoint#usebreakpoint

const isBreakpoint = (breakpoint: string): boolean => {
  const value = (screens[breakpoint] as string) ?? "999999px";
  const query = window.matchMedia(`(min-width: ${value})`);

  return query.matches;
};

const useBreakpoint = (breakpoint: string, defaultValue = false): boolean => {
  const [match, setMatch] = useState(() => defaultValue);
  const matchRef = useRef(defaultValue);

  useEffect(() => {
    if (!(isBrowser && "matchMedia" in window)) return undefined;

    function track() {
      matchRef.current = isBreakpoint(breakpoint);

      if (matchRef.current != match) {
        setMatch(matchRef.current);
      }
    }

    window.addEventListener("resize", track);
    track();

    return () => window.removeEventListener("resize", track);
  });

  return match;
};

export { isBreakpoint, useBreakpoint };
