import React, { memo } from "react";
import { Outlet } from "react-router-dom";

const Empty: React.FC<{
  children?: React.ReactNode;
}> = ({ children = null }) => (
  <div className="flex h-screen flex-col bg-lynch-700 text-white">
    <main className="m-auto flex flex-col items-center justify-center">
      {children || <Outlet />}
    </main>
  </div>
);

export default memo(Empty);
