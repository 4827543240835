import { SocialButtonType, SSOButton } from "@getwellen/valesco";
import React from "react";
import { titleize } from "utils/text";

const SocialButtons: React.FC<{
  buttons?: SocialButtonType[];
  ctaText?: string;
  onClick(buttonType: SocialButtonType): void;
}> = ({ ctaText = "Login with", buttons = [], onClick }) => (
  <ul className="mb-6">
    {buttons.map((button, idx) => (
      <li key={idx} className="mb-2 last:mb-0">
        <SSOButton
          key={idx}
          buttonType={button}
          onClick={(e: React.SyntheticEvent) => {
            e.preventDefault();
            onClick(button);
          }}
        >
          {ctaText} {titleize(button)}
        </SSOButton>
      </li>
    ))}
  </ul>
);

export { SocialButtons };
