import { useIntake } from "contexts";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const IntakeProgress: React.FC<{
  className?: string;
}> = ({ className = "" }) => {
  const { fitnessProfile, healthHistory } = useIntake();
  const [fillPercent, setFillPercent] = useState<number>(0.0);
  const { stepNum } = useParams();

  const { intakePage } = useIntake();

  useEffect(() => {
    const alreadyAnswered: string[] = [];

    // we need to de-dupe here because some questions can have
    // multiple answers. each pass of the reduce, we first check
    // to see if the answer key (which is the question) has already
    // been counted. if it has, we just keep going. otherwise
    // we pop it into the already answered list and move forward
    // incrementing by 1
    const totalAnswered =
      fitnessProfile.reduce((prev, current) => {
        if (alreadyAnswered.includes(current.key)) return prev;

        alreadyAnswered.push(current.key);

        return prev + 1;
      }, 0) +
      healthHistory.reduce((prev, current) => {
        if (alreadyAnswered.includes(current.key)) return prev;

        alreadyAnswered.push(current.key);

        return prev + 1;
      }, 0);

    const totalQuestions =
      (intakePage.fitnessProfile?.data?.attributes?.questions || []).length +
      (intakePage.healthHistory?.data?.attributes?.questions || []).length;

    if (totalQuestions > 0) {
      setFillPercent((totalAnswered / totalQuestions) * 100);
    }
  }, [intakePage, stepNum]);

  return (
    <div className={className}>
      <div className="flex h-3 w-full flex-row bg-cararra-300  shadow-sm md:hidden md:pt-0">
        <div
          className="h-full bg-geebung-500"
          style={{ width: `${fillPercent}%` }}
        ></div>
      </div>
    </div>
  );
};

export { IntakeProgress };
