import { ComponentContentQuizQuestion, Quiz } from "graphql/strapi-cms";

const getQuestionByApiId = (quiz: Quiz, apiId: string) =>
  (quiz?.questions || []).find(
    (q) => q?.apiId === apiId
  ) as ComponentContentQuizQuestion;

const getAnswerByApiId = (
  question: ComponentContentQuizQuestion,
  answerApiId: string
) => (question?.answers || []).filter((a) => a?.apiId === answerApiId);

export { getAnswerByApiId, getQuestionByApiId };
