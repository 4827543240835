import { LoadingSkeleton } from "components/loading/LoadingSkeleton";
import { LoadingSkeletonIcon } from "components/loading/LoadingSkeletonIcon";

export function LoadingEntry() {
  return (
    <div className="flex w-full flex-col items-stretch gap-y-2 text-sm leading-[0.875rem]">
      <LoadingSkeleton height="14px" width="20%" />
      <LoadingSkeleton height="14px" width="50%" />
    </div>
  );
}

export function LoadingLink({ width = "40%" }: { width?: string | number }) {
  return (
    <span className="flex w-full flex-row items-center gap-x-2 py-3">
      <LoadingSkeletonIcon
        className="mr-0"
        containerClassName="!text-2xl !leading-6"
      />
      <LoadingSkeleton
        containerClassName="!text-xs !leading-[0.875rem] flex-1"
        height="14px"
        width={width}
      />
    </span>
  );
}
