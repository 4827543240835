import { useCurrentUser } from "contexts/CurrentUserContext";
import { useEffect, useMemo } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { LocationState, resolveBackgroundLocation } from "utils/location";

const useDashboardRoute = () => {
  const {
    currentUserIsOnboarded,
    currentUserIsUnsubscribed,
    currentUserIsTrialExpired,
    currentUserHasSeenWelcomeMessage,
    currentUserHasSubscription
  } = useCurrentUser();
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as LocationState;
  const isDashboard = location.pathname === "/dashboard";
  const [searchParams] = useSearchParams();
  const returnTo = (searchParams.get("returnTo") ||
    locationState?.returnTo) as string;
  const openOverlay = (searchParams.get("openOverlay") ||
    locationState?.openOverlay) as string;
  const shouldSeeWelcomeMessage = useMemo(
    () => !currentUserHasSeenWelcomeMessage && !openOverlay,
    [currentUserHasSeenWelcomeMessage, openOverlay]
  );

  useEffect(() => {
    // user is loaded, but are in an incomplete state
    if (!currentUserIsOnboarded || !currentUserHasSubscription) {
      navigate({
        pathname: "/intake",
        search: location.search
      });
    }
    // check if we need to return to a page
    else if (returnTo) {
      navigate({
        pathname: returnTo,
        search: location.search
      });
    }
    // check if we need to open an overlay
    else if (openOverlay) {
      navigate(openOverlay, {
        state: {
          backgroundLocation: resolveBackgroundLocation(location)
        } as LocationState
      });
    }
    // check if we can open the welcome overlay
    else if (shouldSeeWelcomeMessage) {
      navigate("/dashboard/welcome", {
        state: {
          backgroundLocation: resolveBackgroundLocation(location)
        }
      });
    }
    // user has an expired trial or is unsubscribed
    else if (
      (currentUserIsTrialExpired || currentUserIsUnsubscribed) &&
      isDashboard
    ) {
      navigate(
        {
          pathname: "/my-subscription/choose-your-plan",
          search: location.search
        },
        {
          state: {
            backgroundLocation: resolveBackgroundLocation(location)
          }
        }
      );
    }
  }, [shouldSeeWelcomeMessage]);

  return {
    canAccessDashboard: currentUserHasSubscription
  };
};

export { useDashboardRoute };
