import { format } from "date-fns";

const titleize = (slug: string): string => {
  const words = slug.split("-");

  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    words[i] = word.charAt(0).toUpperCase() + word.slice(1);
  }

  return words.join(" ");
};

const slugify = (str: string): string =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");

const formatDate = (dateStr: string, formatStr = "MMMM do, yyyy"): string => {
  try {
    return format(new Date(dateStr), formatStr);
  } catch (error) {
    console.error(error);
    return "";
  }
};

const formatDateShort = (dateStr: string): string =>
  format(new Date(dateStr), "MMM d, yyyy");

const translateAuthErorr = (error: string): string => {
  switch (error) {
    case "email_required":
      return "We couldn't log you in with your provider. Please provide an email address to continue.";
    case "session_expired":
      return "Your session has expired. Please log back in to continue.";
    case "unknown_error":
      return "An unknown authentication error occurred. Please try again later.";
    default:
      return error;
  }
};

export { formatDate, formatDateShort, slugify, titleize, translateAuthErorr };
