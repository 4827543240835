import { LoadingSkeleton } from "components/loading/LoadingSkeleton";
import { Profile } from "graphql/rails-api";
import { Quiz } from "graphql/strapi-cms";
import React from "react";
import { twMerge } from "tailwind-merge";
import { getAnswerByApiId, getQuestionByApiId } from "utils/quizzes";

import { LoadingEntry } from "./NavSkeletons";

const NavFitnessProfileHeader: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => (
  <h4 className="mb-2 text-sm font-semibold text-cello-300">{children}</h4>
);

const NavFitnessProfileItem: React.FC<{
  className?: string;
  children?: React.ReactNode;
}> = ({ className = "", children }) => (
  <p className={twMerge("align-center flex font-semibold", className)}>
    {children}
  </p>
);

const NavFitnessProfile: React.FC<{
  fitnessProfile: Profile;
  fitnessProfileQuiz: Quiz;
  isLoading?: boolean;
}> = ({ fitnessProfile, fitnessProfileQuiz, isLoading = false }) => {
  const getProfileItems = (apiId: string): string[] => {
    const profileItems = (fitnessProfile?.items || []).filter(
      (i) => i?.key === apiId
    );
    const cmsQuestion = getQuestionByApiId(fitnessProfileQuiz, apiId);

    const cmsAnswers = profileItems
      .map((a) => getAnswerByApiId(cmsQuestion, a?.value))
      .flat();

    return cmsAnswers.map((answer) => answer?.altText || answer?.text || "");
  };

  const items: string[] = getProfileItems("upper_body_strength")
    .concat(getProfileItems("lower_body_strength"))
    .concat(getProfileItems("core_strength"));

  const equipment: string[] = getProfileItems("equipment");

  return (
    <div className="mb-8">
      <h3 className="mb-8 text-xl font-semibold">
        {isLoading ? (
          <LoadingSkeleton borderRadius="9999px" height="40px" width="70%" />
        ) : (
          "My Fitness Profile"
        )}
      </h3>
      <ul>
        <li className="mb-8">
          {isLoading ? (
            <LoadingEntry />
          ) : (
            <>
              <NavFitnessProfileHeader>I can do a...</NavFitnessProfileHeader>
              <NavFitnessProfileItem>{items.join(", ")}</NavFitnessProfileItem>
            </>
          )}
        </li>
        <li className="mb-8 empty:hidden">
          {isLoading ? (
            <LoadingEntry />
          ) : (
            <>
              <NavFitnessProfileHeader>I have...</NavFitnessProfileHeader>
              <NavFitnessProfileItem>
                {equipment.join(", ")}
              </NavFitnessProfileItem>
            </>
          )}
        </li>
      </ul>
    </div>
  );
};

export { NavFitnessProfile };
