import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useLocalStorage } from "react-use";

export enum MembershipPartner {
  RenewActive = "renewactive",
  OnePass = "onepass"
}

export type MembershipReferral = {
  code: string;
  partner: MembershipPartner;
  sector?: string;
};

export const translatePartner = (label: string) => {
  if (label === MembershipPartner.RenewActive) {
    return "Renew Active";
  } else if (label === MembershipPartner.OnePass) {
    return "One Pass";
  }

  return "Your";
};

export const getMembershipPartnerLabel = (referral: MembershipReferral) => {
  return translatePartner(referral.partner);
};

export default function useMembershipReferral() {
  const [searchParams] = useSearchParams();
  const membershipCode = searchParams.get("mbmrshp_code");
  const membershipPartner = searchParams.get("mbmrshp_partner");
  const membershipSector = searchParams.get("mbmrshp_sector");

  const [membershipReferral, setMembershipReferral] =
    useLocalStorage<MembershipReferral>("wellen.membershipReferral", undefined);

  const updateMembershipReferral = (referral: MembershipReferral) => {
    setMembershipReferral(referral);
  };

  const clearReferral = () => {
    window.localStorage.removeItem("wellen.membershipReferral");
  };

  useEffect(() => {
    const isValidPartner = Object.values(MembershipPartner).includes(
      membershipPartner as MembershipPartner
    );

    if (membershipPartner && isValidPartner) {
      updateMembershipReferral({
        code: String(membershipCode || ""),
        partner: membershipPartner as MembershipPartner,
        sector: membershipSector ?? undefined
      });
    }
  }, []);

  return {
    isMemberhipReferral: !!membershipReferral,
    membershipReferral,
    updateMembershipReferral,
    clearReferral,
    isOnePass: Boolean(
      membershipReferral?.partner === MembershipPartner.OnePass
    )
  };
}
