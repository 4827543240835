import { ProfileItemAttributes, StripePrice } from "graphql/rails-api";
import {
  IntakePage,
  Quiz,
  SubscriptionPlan,
  useFitnessProfileQuizQuery,
  useHealthHistoryQuizQuery,
  useIntakePageQuery
} from "graphql/strapi-cms";
import { useCustomerIoLeadEmail } from "hooks/useCustomerIoLeadEmail";
import { useStripePrice } from "hooks/useStripePrice";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export type AccountSetupInput = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  provider: string;
  uid: string;
};

export type IntakePageData = {
  fitnessProfile: ProfileItemAttributes[];
  accountSetup: AccountSetupInput;
  healthHistory: ProfileItemAttributes[];
  selectedPlan: SubscriptionPlan;
};

export type SubscriptionPlanWithPricing = SubscriptionPlan &
  Omit<StripePrice, "interval" | "__typename">;

interface IntakeContext {
  fitnessProfile: ProfileItemAttributes[];
  setFitnessProfile: React.Dispatch<
    React.SetStateAction<ProfileItemAttributes[]>
  >;
  accountSetup: AccountSetupInput;
  setAccountSetup: React.Dispatch<React.SetStateAction<AccountSetupInput>>;
  healthHistory: ProfileItemAttributes[];
  setHealthHistory: React.Dispatch<
    React.SetStateAction<ProfileItemAttributes[]>
  >;
  selectedPlan: SubscriptionPlan | null;
  setSelectedPlan: React.Dispatch<React.SetStateAction<SubscriptionPlan>>;
  hasAcceptedDisclaimer: boolean;
  setHasAcceptedDisclaimer: React.Dispatch<React.SetStateAction<boolean>>;
  clearProfile(): void;
  version: string;
  intakePage: IntakePage;
  subscriptionPlans: SubscriptionPlanWithPricing[];
  isLoading: boolean;
  isPricingLoading: boolean;
  fitnessProfileQuiz?: Quiz | undefined;
  healthHistoryQuiz?: Quiz | undefined;
}

const defaultState: IntakeContext = {
  fitnessProfile: [],
  setFitnessProfile: () => null,
  accountSetup: {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    uid: "",
    provider: ""
  },
  setAccountSetup: () => null,
  healthHistory: [],
  setHealthHistory: () => null,
  selectedPlan: null,
  setSelectedPlan: () => null,
  hasAcceptedDisclaimer: false,
  setHasAcceptedDisclaimer: () => null,
  clearProfile: () => null,
  version: "v3",
  intakePage: {},
  subscriptionPlans: [],
  fitnessProfileQuiz: undefined,
  healthHistoryQuiz: undefined,
  isLoading: false,
  isPricingLoading: false
};

const IntakeContext = createContext<IntakeContext>(defaultState);

const getProfile = (): IntakeContext => {
  const profile = JSON.parse(localStorage.getItem("intake") || "{}");

  if (profile && profile.version !== "v3") return {} as IntakeContext;

  return profile;
};

const IntakeProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const location = useLocation();
  const [profile] = useState<IntakeContext>(() => getProfile());
  const [hasAcceptedDisclaimer, setHasAcceptedDisclaimer] = useState(
    profile.hasAcceptedDisclaimer || defaultState.hasAcceptedDisclaimer
  );

  const [fitnessProfile, setFitnessProfile] = useState(
    profile?.fitnessProfile || defaultState.fitnessProfile
  );
  const [accountSetup, setAccountSetup] = useState(
    profile?.accountSetup || (defaultState.accountSetup as AccountSetupInput)
  );
  const [healthHistory, setHealthHistory] = useState(
    profile?.healthHistory || defaultState.healthHistory
  );
  const [selectedPlan, setSelectedPlan] = useState(
    profile?.selectedPlan || (defaultState.selectedPlan as SubscriptionPlan)
  );

  const version = profile.version || defaultState.version;
  const leadEmail = useCustomerIoLeadEmail();

  const { loading: intakePageLoading, data: intakePageData } =
    useIntakePageQuery({
      fetchPolicy: "cache-first"
    });

  const { loading: healthHistoryQuizLoading, data: healthHistoryQuizData } =
    useHealthHistoryQuizQuery({
      fetchPolicy: "cache-first",
      skip: !location.pathname.includes("health-history")
    });

  const { loading: fitnessProfileLoading, data: fitnessProfileQuizData } =
    useFitnessProfileQuizQuery({
      fetchPolicy: "cache-first",
      skip: !location.pathname.includes("fitness-profile")
    });

  const fitnessProfileQuiz = fitnessProfileQuizData?.quizzes?.data?.[0]
    ?.attributes as Quiz;
  const healthHistoryQuiz = healthHistoryQuizData?.quizzes?.data?.[0]
    ?.attributes as Quiz;

  const plans = (
    intakePageData?.intakePage?.data?.attributes?.planSelector
      ?.subscriptionPlans?.data || []
  ).map((p) => p.attributes as SubscriptionPlan);

  const { data: pricingData, isLoading: isPricingLoading } = useStripePrice(
    plans
      .map((p: SubscriptionPlan) => p?.stripePriceId as string)
      .filter((p) => !!p)
  );

  useEffect(() => {
    if (leadEmail) {
      setAccountSetup({
        ...accountSetup,
        email: leadEmail
      });
    }
  }, [leadEmail]);

  useEffect(() => {
    localStorage.setItem(
      "intake",
      JSON.stringify({
        fitnessProfile,
        healthHistory,
        accountSetup,
        selectedPlan,
        version
      })
    );
  }, [fitnessProfile, healthHistory, accountSetup, selectedPlan]);

  return (
    <IntakeContext.Provider
      value={{
        fitnessProfile,
        setFitnessProfile,
        accountSetup,
        setAccountSetup,
        healthHistory,
        setHealthHistory,
        selectedPlan,
        setSelectedPlan,
        hasAcceptedDisclaimer,
        setHasAcceptedDisclaimer,
        clearProfile: () => {
          localStorage.removeItem("intake");
        },
        version,
        intakePage: intakePageData?.intakePage?.data?.attributes as IntakePage,
        subscriptionPlans: plans.map((plan) => {
          const pricing =
            pricingData?.find(
              (price) => price.id === (plan?.stripePriceId as string)
            ) || {};

          return {
            ...plan,
            ...pricing,
            interval: plan.interval,
            planType: plan.planType
          } as SubscriptionPlanWithPricing;
        }),
        healthHistoryQuiz,
        fitnessProfileQuiz,
        isLoading:
          intakePageLoading ||
          healthHistoryQuizLoading ||
          fitnessProfileLoading,
        isPricingLoading: isPricingLoading
      }}
    >
      {children}
    </IntakeContext.Provider>
  );
};

const useIntake = () => {
  const context = useContext(IntakeContext);

  if (!context)
    throw new Error("useContext must be used within IntakeProvider");

  return context;
};

export { IntakeContext, IntakeProvider, useIntake };
