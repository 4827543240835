import { useAuth } from "contexts/AuthContext";
import { useCurrentUser } from "contexts/CurrentUserContext";
import { AccountSetupInput } from "contexts/IntakeContext";
import { usePostLoginAnalytics } from "pages/auth/usePostLogin";
import LoadingPage from "pages/LoadingPage";
import React, { memo, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useOrderCreateUser } from "./OrderCreateAccount";
import { orderRoute, OsteoboostOrderSlug } from "./OsteoboostOrderPage";

const OrderAuthCallback: React.FC = () => {
  const { getAccessTokenSilently, authUser, authError, logout } = useAuth();
  const { currentUser } = useCurrentUser();
  const navigate = useNavigate();

  const { createUser } = useOrderCreateUser(undefined as any);
  const { postLoginAnalytics } = usePostLoginAnalytics();

  useEffect(() => {
    if (authError) {
      navigate(
        orderRoute(OsteoboostOrderSlug.Login) + "?authError=" + authError,
        {
          replace: true
        }
      );
    } else if (authUser && !authUser?.email) {
      logout({
        returnTo:
          orderRoute(OsteoboostOrderSlug.Login) + "?authError=email_required",
        resetOnboarding: false
      });
    } else if (!authUser && !authError) {
      logout({
        returnTo:
          orderRoute(OsteoboostOrderSlug.Login) + "?authError=unknown_error",
        resetOnboarding: false
      });
    } else if (currentUser) {
      postLoginAnalytics(currentUser);
      navigate(orderRoute(OsteoboostOrderSlug.Payment), { replace: true });
    } else if (authUser && !currentUser) {
      createUser({ accessToken: getAccessTokenSilently() }, {
        firstName: authUser.given_name as string,
        lastName: authUser.family_name ?? "",
        provider: authUser.sub.split("|")[0] as string,
        uid: authUser.sub.split("|")[1] as string,
        email: authUser.email
      } as AccountSetupInput).then(() => {
        navigate(orderRoute(OsteoboostOrderSlug.Payment), {
          replace: true
        });
      });
    }
  }, []);

  return <LoadingPage />;
};

export default memo(OrderAuthCallback);
