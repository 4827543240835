export type OrderNumberProps = {
  orderNumber: string;
  orderDate: string;
};

export const OrderNumber = ({ orderNumber, orderDate }: OrderNumberProps) => {
  return (
    <div className="grid grid-cols-[1fr_auto_1fr] items-start gap-8">
      {/* Left side for Order Number */}
      <div className="grid">
        <span className="font-heading text-lg text-cello-300 font-semibold">
          Order number
        </span>
        <span className="font-display text-4xl md:text-5xl leading-tight">
          {orderNumber}
        </span>
      </div>

      {/* Center divider */}
      <div className="h-full w-[1px] mx-auto bg-lynch-200"></div>

      {/* Right side for Order Date */}
      <div className="grid text-left justify-end">
        <span className="font-heading text-lg text-cello-300 font-semibold">
          Order date
        </span>
        <span className="font-display text-3xl md:text-5xl leading-tight">
          {orderDate}
        </span>
      </div>
    </div>
  );
};
