import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { httpBatchLink, loggerLink } from "@trpc/client";
import { useEffect, useState } from "react";
import superjson from "superjson";
import { trpc } from "utils/trpc";

import { useAuth } from "./AuthContext";

const makeService = (url: string, token?: string | null) =>
  httpBatchLink({
    url,
    fetch(url, options) {
      return fetch(url, {
        ...options,
        credentials: "include"
      });
    },
    headers: () => {
      return {
        authorization: `Bearer ${token}`
      };
    }
  });

export default function TRPCProvider({
  children
}: {
  children: React.ReactNode;
}) {
  const { getAccessTokenSilently } = useAuth();
  const token = getAccessTokenSilently();

  const createClient = (token?: string | null) =>
    trpc.createClient({
      transformer: superjson,
      links: [
        loggerLink({
          enabled: (opts) =>
            import.meta.env.NODE_ENV === "development" ||
            (opts.direction === "down" && opts.result instanceof Error)
        }),
        (runtime) => {
          const services = {
            videoStreaming: makeService(
              (import.meta.env.VITE_VIDEO_API_URL || "http://localhost:3010") +
                "/trpc",
              token
            )(runtime),
            personalization: makeService(
              (import.meta.env.VITE_PERSONALIZATION_API_URL ||
                "http://localhost:3011") + "/trpc",
              token
            )(runtime)
          };

          return (ctx) => {
            const { op } = ctx;
            const pathParts = op.path.split(".");
            const serverName =
              pathParts.shift() as string as keyof typeof services;
            const path = pathParts.join(".");
            const link = services[serverName];

            return link({
              ...ctx,
              op: {
                ...op,
                path
              }
            });
          };
        }
      ]
    });

  const [queryClient] = useState(() => new QueryClient());
  const [trpcClient, setTrpcClient] = useState(() => createClient(token));

  useEffect(() => {
    setTrpcClient(createClient(token));
  }, [token]);

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </trpc.Provider>
  );
}
