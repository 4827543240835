import { LogoVariant, OverlaySize, OverlayTheme } from "@getwellen/valesco";
import * as Sentry from "@sentry/react";
import algoliasearch from "algoliasearch";
import { WelcomeModal } from "components/dashboard/WelcomeModal";
import { useAnalytics } from "contexts/AnalyticsContext";
import { useFeatureFlag } from "contexts/FeatureFlagContext";
import { IntakeProvider } from "contexts/IntakeContext";
import { OsteoboostOrderProvider } from "contexts/OsteoboostOrderContext";
import { PaymentProvider } from "contexts/PaymentContext";
import {
  EmptyLayout,
  MainLayout,
  OverlayLayout,
  PatientLayout,
  PublicLayout,
  QuizLayout
} from "layouts";
import { IntakePageSlug } from "pages/intake/IntakePage";
import MaintenancePage from "pages/MaintenancePage";
import {
  orderRoute,
  OsteoboostOrderSlug
} from "pages/order/OsteoboostOrderPage";
import { type TaskRenderProps } from "pages/workout-program/WorkoutProgramDayPage/WorkoutProgramDayPage";
import React, { useEffect } from "react";
import { InstantSearch } from "react-instantsearch-hooks-web";
import {
  createRoutesFromChildren,
  matchRoutes,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigationType
} from "react-router-dom";

import DashboardRoute from "./DashboardRoute";
import { lazyRetry } from "./lazyRetry";
import OnboardingRoute from "./OnboardingRoute";
import OsteoboostOrderRoute from "./OsteoboostOrderRoute";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

// pages
const OsteoboostOrderPage = lazyRetry(
  () => import("pages/order/OsteoboostOrderPage")
);
const OrderAuthCallbackPage = lazyRetry(
  () => import("pages/order/OrderAuthCallback")
);
const DashboardPage = lazyRetry(() => import("pages/dashboard/DashboardPage"));
const MyAssessmentsPage = lazyRetry(
  () => import("pages/dashboard/MyAssessments")
);
const CreateProgramPage = lazyRetry(
  () => import("pages/dashboard/CreateProgramPage")
);
const PersonalizeProgramPage = lazyRetry(
  () => import("pages/dashboard/PersonalizeProgramPage")
);
const PersonalizeWorkoutPage = lazyRetry(
  () => import("pages/dashboard/PersonalizeWorkoutPage")
);
const WorkoutProgramDayPage = lazyRetry(
  () => import("pages/workout-program/WorkoutProgramDayPage")
);
const WorkoutProgramDayTaskCard = lazyRetry(
  () => import("components/workout-program/WorkoutProgramDayTaskCard")
);
const WorkoutProgramDayTask = lazyRetry(
  () => import("pages/workout-program/WorkoutProgramDayTaskPage")
);
const WorkoutProgramAssessmentPage = lazyRetry(
  () => import("pages/workout-program/assessment/WorkoutProgramAssessmentPage")
);
const WorkoutProgramWellTipQuizPage = lazyRetry(
  () => import("pages/workout-program/well-tip/WorkoutProgramWellTipQuizPage")
);
const SharedWorkoutPage = lazyRetry(
  () => import("pages/workout-program/SharedWorkoutPage")
);
const PatientWorkoutPage = lazyRetry(
  () => import("pages/patients/PatientWorkoutPage")
);
const WorkoutProgramAssessmentResultsPage = lazyRetry(
  () =>
    import(
      "pages/workout-program/assessment/WorkoutProgramAssessmentResultsPage"
    )
);
const ExerciseViewPage = lazyRetry(
  () => import("pages/exercise/ExerciseViewPage")
);
const IntakePage = lazyRetry(() => import("pages/intake/IntakePage"));
const IntakePageAuthCallback = lazyRetry(
  () => import("pages/intake/IntakePageAuthCallback")
);
const CompleteOnboardingPage = lazyRetry(
  () => import("pages/intake/CompleteOnboardingPage")
);
const LoginPage = lazyRetry(() => import("pages/auth/LoginPage"));
const ResetPasswordPage = lazyRetry(
  () => import("pages/auth/ResetPasswordPage")
);
const ResetPasswordSentPage = lazyRetry(
  () => import("pages/auth/ResetPasswordSentPage")
);
const ChangePasswordPage = lazyRetry(
  () => import("pages/auth/ChangePasswordPage")
);
const ChangePasswordCompletePage = lazyRetry(
  () => import("pages/auth/ChangePasswordCompletePage")
);
const AuthCallbackPage = lazyRetry(() => import("pages/auth/AuthCallbackPage"));
const NotFoundPage = lazyRetry(() => import("pages/NotFoundPage"));
const UnauthorizedPage = lazyRetry(() => import("pages/UnauthorizedPage"));
const ErrorPage = lazyRetry(() => import("pages/ErrorPage"));

// overlays
const AccountEditOverlay = lazyRetry(
  () => import("pages/overlays/AccountEditOverlay")
);
const FitnessProfileEditOverlay = lazyRetry(
  () => import("pages/overlays/FitnessProfileEditOverlay")
);
const HealthDisclaimerOverlay = lazyRetry(
  () => import("pages/overlays/HealthDisclaimerOverlay")
);
const MembershipReferralOverlay = lazyRetry(
  () => import("pages/overlays/MembershipReferralOverlay")
);
const IntakeProviderLocatorOverlay = lazyRetry(
  () => import("pages/overlays/intake/IntakeProviderLocatorOverlay")
);
const IntakeCreateAccountOverlay = lazyRetry(
  () => import("pages/overlays/intake/IntakeCreateAccountOverlay")
);
const SubscriptionCancelOverlay = lazyRetry(
  () => import("pages/overlays/subscription/SubscriptionCancelOverlay")
);
const SubscriptionStartTrialOverlay = lazyRetry(
  () => import("pages/overlays/subscription/SubscriptionStartTrialOverlay")
);
const SubscriptionStartOverlay = lazyRetry(
  () => import("pages/overlays/subscription/SubscriptionStartOverlay")
);
const SubscriptionEditPaymentOverlay = lazyRetry(
  () => import("pages/overlays/subscription/SubscriptionEditPaymentOverlay")
);
const SubscriptionPlanSelectionOverlay = lazyRetry(
  () => import("pages/overlays/subscription/SubscriptionPlanSelectionOverlay")
);
const SubscriptionPlanChangeSummaryOverlay = lazyRetry(
  () =>
    import("pages/overlays/subscription/SubscriptionPlanChangeSummaryOverlay")
);
const WorkoutFeedbackOverlay = lazyRetry(
  () => import("pages/overlays/WorkoutFeedbackOverlay")
);
const WorkoutProgramTaskActivityOverlay = lazyRetry(
  () => import("pages/overlays/WorkoutProgramTaskActivityOverlay")
);
const AssessmentOverlay = lazyRetry(
  () => import("pages/overlays/AssessmentOverlay")
);
const ShareableWorkoutOverlay = lazyRetry(
  () => import("pages/overlays/shareable/ShareableWorkoutOverlay")
);
const ShareableJoinWellenOverlay = lazyRetry(
  () => import("pages/overlays/shareable/ShareableJoinWellenOverlay")
);
const MusicSettingsOverlay = lazyRetry(
  () => import("pages/overlays/music/MusicSettingsOverlay")
);
const MusicVendorInfoOverlay = lazyRetry(
  () => import("pages/overlays/music/MusicVendorInfoOverlay")
);
const PTNotesOverlay = lazyRetry(
  () => import("pages/overlays/provider-workout/PTNotesOverlay")
);

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

if (Boolean(import.meta.env.VITE_SENTRY_DSN)) {
  const options: Sentry.BrowserOptions = {
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      })
    ],
    tracesSampleRate: 0.1
  };

  if (import.meta.env.VITE_SENTRY_ENV) {
    options.environment = import.meta.env.VITE_SENTRY_ENV;
  }

  if (import.meta.env.VITE_SENTRY_RELEASE) {
    options.release = import.meta.env.VITE_SENTRY_RELEASE;
  }

  Sentry.init(options);
}

const searchClient = algoliasearch(
  import.meta.env.VITE_ALGOLIA_APP_ID || "",
  import.meta.env.VITE_ALGOLIA_SEARCH_KEY || ""
);

const Router: React.FC = () => {
  const location = useLocation();
  const state = location.state as { backgroundLocation?: Location };
  const analytics = useAnalytics();
  const backgroundLocation = state?.backgroundLocation;
  const { isFeatureEnabled, isLoading } = useFeatureFlag();
  const maintenanceMode = isFeatureEnabled("web-app_maintenance-mode");

  // This effect should only trigger if we have analytics and we are not in
  // maintenanceMode.
  //
  // Previously this hook was causing the page to hang in development due to
  // conditionally rendering the effect.
  //
  // The effect would run, then not run, then run again because due to
  // useFeatureFlag hook being async (calling to the Rails API to featch feature flags)
  //
  // To correct this behavior we hoist the conditional logic of running
  // analytics when not in maintenance mode to the useEffect hook itself.
  //
  // To prevent page crashes due to internal React invariants
  // (hooks being called conditionally between renders) use the
  // `eslint-plugin-react-hooks` linter to catch conditional renders
  //
  // Reference: https://react.dev/warnings/invalid-hook-call-warning
  useEffect(
    () => {
      if (analytics && !maintenanceMode) {
        analytics.page(location.pathname);
      }
    },
    // It is important to re-run this effect when the FeatureFlag hook is done
    // loading as well.
    [location, analytics, isLoading, maintenanceMode]
  );

  if (maintenanceMode) {
    return (
      <EmptyLayout>
        <MaintenancePage />
      </EmptyLayout>
    );
  }

  return (
    <>
      {/* normal routes, rendered under overlays */}
      <SentryRoutes location={backgroundLocation || location}>
        {/* redirects */}
        <Route element={<Navigate to="/dashboard" />} index />
        {/* public routes */}

        {/* Osteoboost Pre-order Route */}
        <Route element={<OsteoboostOrderRoute />}>
          <Route
            element={
              <OsteoboostOrderProvider>
                <PublicLayout
                  fullHeight={true}
                  loginRedirect={orderRoute(OsteoboostOrderSlug.Login)}
                  logoHref="https://osteoboost.com/"
                  logoutRedirect={orderRoute(OsteoboostOrderSlug.Login)}
                  logoVariant={LogoVariant.Osteoboost}
                  showAppBar={true}
                />
              </OsteoboostOrderProvider>
            }
            path="/order"
          >
            <Route
              element={
                <React.Suspense fallback={<></>}>
                  <OsteoboostOrderPage />
                </React.Suspense>
              }
              path="/order/:slug"
            />

            <Route
              element={
                <React.Suspense fallback={<></>}>
                  <OrderAuthCallbackPage />
                </React.Suspense>
              }
              path="/order/authCallback"
            />
          </Route>
        </Route>

        {/* -- Onboarding Route */}
        <Route element={<OnboardingRoute />}>
          <Route
            element={
              <IntakeProvider>
                <PublicLayout
                  disableTopPadding={[
                    `/intake/${IntakePageSlug.CompleteOnboarding}`
                  ].includes(location.pathname)}
                  showAppBar={
                    !location.pathname.includes(
                      IntakePageSlug.CompleteOnboarding
                    )
                  }
                />
              </IntakeProvider>
            }
            path="/intake"
          >
            <Route
              element={
                <React.Suspense fallback={<></>}>
                  <IntakePage />
                </React.Suspense>
              }
              path="/intake/:slug/:stepNum"
            />
            <Route
              element={
                <React.Suspense fallback={<></>}>
                  <IntakePage />
                </React.Suspense>
              }
              path="/intake/:slug"
            />
            <Route
              element={
                <EmptyLayout>
                  <React.Suspense fallback={<></>}>
                    <CompleteOnboardingPage />
                  </React.Suspense>
                </EmptyLayout>
              }
              path="/intake/completeOnboarding"
            />
            <Route
              element={
                <React.Suspense fallback={<></>}>
                  <IntakePageAuthCallback />
                </React.Suspense>
              }
              path="/intake/authCallback"
            />
          </Route>
        </Route>

        <Route element={<PublicRoute />}>
          <Route
            element={
              <PublicLayout>
                <React.Suspense fallback={<></>}>
                  <LoginPage />
                </React.Suspense>
              </PublicLayout>
            }
            path="/login"
          />
          <Route
            element={
              <PublicLayout>
                <React.Suspense fallback={<></>}>
                  <ResetPasswordPage />
                </React.Suspense>
              </PublicLayout>
            }
            path="/resetPassword"
          />
          <Route
            element={
              <PublicLayout>
                <React.Suspense fallback={<></>}>
                  <ResetPasswordSentPage />
                </React.Suspense>
              </PublicLayout>
            }
            path="/resetPassword/sent"
          />
          <Route
            element={
              <PublicLayout>
                <React.Suspense fallback={<></>}>
                  <ChangePasswordCompletePage />
                </React.Suspense>
              </PublicLayout>
            }
            path="/changePassword/complete"
          />
          <Route
            element={
              <PublicLayout>
                <React.Suspense fallback={<></>}>
                  <ChangePasswordPage />
                </React.Suspense>
              </PublicLayout>
            }
            path="/changePassword/:token"
          />
          <Route
            element={
              <React.Suspense fallback={<></>}>
                <AuthCallbackPage />
              </React.Suspense>
            }
            path="/authCallback"
          />
          <Route
            element={
              <React.Suspense fallback={<></>}>
                <SharedWorkoutPage />
              </React.Suspense>
            }
            path="/s/:code"
          />
          <Route
            element={
              <React.Suspense fallback={<></>}>
                <PatientLayout>
                  <PatientWorkoutPage />
                </PatientLayout>
              </React.Suspense>
            }
            path="/patient-workout/:slug"
          />
          <Route
            element={
              <React.Suspense fallback={<></>}>
                <PatientLayout>
                  <ExerciseViewPage />
                </PatientLayout>
              </React.Suspense>
            }
            path="/patient-workout/exercise/:slug"
          />
        </Route>

        {/* /private routes */}
        <Route element={<PrivateRoute />}>
          <Route element={<DashboardRoute />}>
            <Route element={<QuizLayout />}>
              <Route
                element={
                  <React.Suspense fallback={<></>}>
                    <WorkoutProgramWellTipQuizPage />
                  </React.Suspense>
                }
                path="/workout-program/:dayKey/:taskKey/quiz"
              />
            </Route>
            <Route element={<MainLayout />} path="/workout-program">
              <Route
                element={
                  <React.Suspense fallback={<></>}>
                    <WorkoutProgramDayPage
                      renderTask={(props: TaskRenderProps) => (
                        <WorkoutProgramDayTaskCard
                          {...props}
                          alternateLoadingSkeleton
                        />
                      )}
                    />
                  </React.Suspense>
                }
                path="/workout-program/:dayKey"
              />
              <Route
                element={
                  <React.Suspense fallback={<></>}>
                    <WorkoutProgramDayTask />
                  </React.Suspense>
                }
                path="/workout-program/:dayKey/:taskKey"
              />
              <Route
                element={
                  <React.Suspense fallback={<></>}>
                    <WorkoutProgramAssessmentPage />
                  </React.Suspense>
                }
                path="/workout-program/:dayKey/:taskKey/:assessmentSlug"
              />
              <Route
                element={
                  <React.Suspense fallback={<></>}>
                    <WorkoutProgramAssessmentResultsPage />
                  </React.Suspense>
                }
                path="/workout-program/:dayKey/:taskKey/:assessmentSlug/results"
              />
            </Route>
            <Route path="/dashboard">
              <Route
                element={
                  <MainLayout>
                    <React.Suspense fallback={<></>}>
                      <DashboardPage />
                    </React.Suspense>
                  </MainLayout>
                }
                index
              />
              <Route
                element={
                  <EmptyLayout>
                    <React.Suspense fallback={<></>}>
                      <CreateProgramPage />
                    </React.Suspense>
                  </EmptyLayout>
                }
                path="/dashboard/createProgram"
              />
              <Route
                element={
                  <EmptyLayout>
                    <React.Suspense fallback={<></>}>
                      <PersonalizeProgramPage />
                    </React.Suspense>
                  </EmptyLayout>
                }
                path="/dashboard/personalizeProgram"
              />
              <Route
                element={
                  <EmptyLayout>
                    <React.Suspense fallback={<></>}>
                      <PersonalizeWorkoutPage />
                    </React.Suspense>
                  </EmptyLayout>
                }
                path="/dashboard/personalizeWorkout"
              />
            </Route>
            <Route path="/my-assessments">
              <Route
                element={
                  <MainLayout>
                    <React.Suspense fallback={<></>}>
                      <MyAssessmentsPage />
                    </React.Suspense>
                  </MainLayout>
                }
                index
              />
            </Route>
            <Route element={<MainLayout />} path="/exercise">
              <Route
                element={
                  <React.Suspense fallback={<></>}>
                    <ExerciseViewPage />
                  </React.Suspense>
                }
                path="/exercise/:slug"
              />
            </Route>
          </Route>
        </Route>

        {/* error pages */}
        <Route element={<EmptyLayout />} path="/unauthorized">
          <Route
            element={
              <React.Suspense fallback={<></>}>
                <UnauthorizedPage />
              </React.Suspense>
            }
            index
          />
        </Route>
        <Route element={<EmptyLayout />} path="/error">
          <Route
            element={
              <React.Suspense fallback={<></>}>
                <ErrorPage />
              </React.Suspense>
            }
            index
          />
        </Route>
        <Route
          element={
            <EmptyLayout>
              <React.Suspense fallback={<></>}>
                <NotFoundPage />
              </React.Suspense>
            </EmptyLayout>
          }
          path="*"
        />
      </SentryRoutes>
      {/* render overlay routes, on top of normal routes */}
      {backgroundLocation && (
        <SentryRoutes>
          <Route
            element={
              <OverlayLayout theme={OverlayTheme.Light} withoutFrame={true}>
                <React.Suspense fallback={<></>}>
                  <WelcomeModal />
                </React.Suspense>
              </OverlayLayout>
            }
            path="/dashboard/welcome"
          />
          <Route
            element={
              <OverlayLayout size={OverlaySize.Large}>
                <React.Suspense fallback={<></>}>
                  <InstantSearch
                    indexName={
                      import.meta.env.VITE_ALGOLIA_INDEX_NAME ||
                      "Provider_development"
                    }
                    searchClient={searchClient}
                  >
                    <IntakeProviderLocatorOverlay />
                  </InstantSearch>
                </React.Suspense>
              </OverlayLayout>
            }
            path="/subscribe/find-a-provider"
          />
          <Route
            element={
              <OverlayLayout>
                <React.Suspense fallback={<></>}>
                  <HealthDisclaimerOverlay />
                </React.Suspense>
              </OverlayLayout>
            }
            path="/subscribe/health-disclaimer"
          />
          <Route
            element={
              <OverlayLayout>
                <React.Suspense fallback={<></>}>
                  <MembershipReferralOverlay />
                </React.Suspense>
              </OverlayLayout>
            }
            path="/subscribe/membership-referral"
          />
          <Route
            element={
              <OverlayLayout size={OverlaySize.Large}>
                <React.Suspense fallback={<></>}>
                  <PaymentProvider>
                    <SubscriptionStartTrialOverlay
                      navigateTo="/intake/fitness-profile/1"
                      unlockProgram={false}
                    />
                  </PaymentProvider>
                </React.Suspense>
              </OverlayLayout>
            }
            path="/subscribe/:planType/start-trial/:interval"
          />
          <Route
            element={
              <OverlayLayout>
                <React.Suspense fallback={<></>}>
                  <IntakeCreateAccountOverlay />
                </React.Suspense>
              </OverlayLayout>
            }
            path="/subscribe/create-your-account"
          />
          <Route
            element={
              <OverlayLayout size={OverlaySize.Large}>
                <React.Suspense fallback={<></>}>
                  <AccountEditOverlay />
                </React.Suspense>
              </OverlayLayout>
            }
            path="/my-account/:slug"
          />
          <Route
            element={
              <OverlayLayout>
                <React.Suspense fallback={<></>}>
                  <WorkoutFeedbackOverlay />
                </React.Suspense>
              </OverlayLayout>
            }
            path="/workout/:workoutId/add-feedback"
          />
          <Route
            element={
              <OverlayLayout size={OverlaySize.Medium}>
                <React.Suspense fallback={<></>}>
                  <AssessmentOverlay />
                </React.Suspense>
              </OverlayLayout>
            }
            path="/my-assessments/:slug"
          />
          <Route path="/fitness-profile">
            <Route
              element={
                <OverlayLayout>
                  <React.Suspense fallback={<></>}>
                    <FitnessProfileEditOverlay />
                  </React.Suspense>
                </OverlayLayout>
              }
              path="/fitness-profile/edit"
            />
          </Route>
          <Route element={<PrivateRoute />} path="/my-subscription">
            <Route
              element={
                <OverlayLayout size={OverlaySize.Large}>
                  <React.Suspense fallback={<></>}>
                    <SubscriptionPlanSelectionOverlay />
                  </React.Suspense>
                </OverlayLayout>
              }
              path="/my-subscription/choose-your-plan"
            />
            <Route
              element={
                <OverlayLayout size={OverlaySize.Large}>
                  <React.Suspense fallback={<></>}>
                    <SubscriptionPlanChangeSummaryOverlay />
                  </React.Suspense>
                </OverlayLayout>
              }
              path="/my-subscription/:planType/change/:interval"
            />
            <Route
              element={
                <OverlayLayout size={OverlaySize.Large}>
                  <React.Suspense fallback={<></>}>
                    <PaymentProvider>
                      <SubscriptionStartOverlay />
                    </PaymentProvider>
                  </React.Suspense>
                </OverlayLayout>
              }
              path="/my-subscription/:planType/start/:interval"
            />
            <Route
              element={
                <OverlayLayout size={OverlaySize.Large}>
                  <React.Suspense fallback={<></>}>
                    <PaymentProvider>
                      <SubscriptionStartTrialOverlay navigateTo="/dashboard" />
                    </PaymentProvider>
                  </React.Suspense>
                </OverlayLayout>
              }
              path="/my-subscription/:planType/start-trial/:interval"
            />
            <Route
              element={
                <OverlayLayout size={OverlaySize.Large}>
                  <React.Suspense fallback={<></>}>
                    <PaymentProvider>
                      <SubscriptionEditPaymentOverlay />
                    </PaymentProvider>
                  </React.Suspense>
                </OverlayLayout>
              }
              path="/my-subscription/edit-payment"
            />
            <Route
              element={
                <OverlayLayout size={OverlaySize.Large}>
                  <React.Suspense fallback={<></>}>
                    <SubscriptionCancelOverlay />
                  </React.Suspense>
                </OverlayLayout>
              }
              path="/my-subscription/cancel"
            />
          </Route>
          <Route
            element={
              <OverlayLayout size={OverlaySize.Large}>
                <React.Suspense fallback={<></>}>
                  <WorkoutProgramTaskActivityOverlay />
                </React.Suspense>
              </OverlayLayout>
            }
            path="/workout-program/:dayKey/:taskKey/activity"
          />
          <Route
            element={
              <OverlayLayout size={OverlaySize.Small}>
                <React.Suspense fallback={<></>}>
                  <ShareableWorkoutOverlay />
                </React.Suspense>
              </OverlayLayout>
            }
            path="/share/:code"
          />
          <Route
            element={
              <OverlayLayout size={OverlaySize.Small}>
                <React.Suspense fallback={<></>}>
                  <ShareableJoinWellenOverlay />
                </React.Suspense>
              </OverlayLayout>
            }
            path="/join-wellen"
          />
          <Route
            element={
              <OverlayLayout size={OverlaySize.Small}>
                <React.Suspense fallback={<></>}>
                  <MusicSettingsOverlay />
                </React.Suspense>
              </OverlayLayout>
            }
            path="/workout/music-settings"
          />
          <Route
            element={
              <OverlayLayout size={OverlaySize.Small}>
                <React.Suspense fallback={<></>}>
                  <MusicVendorInfoOverlay />
                </React.Suspense>
              </OverlayLayout>
            }
            path="/workout/vendor-info"
          />
          <Route
            element={
              <OverlayLayout size={OverlaySize.Small}>
                <React.Suspense fallback={<></>}>
                  <PTNotesOverlay />
                </React.Suspense>
              </OverlayLayout>
            }
            path="/patient-workout/notes"
          />
        </SentryRoutes>
      )}
    </>
  );
};

export { Router };
